import React from "react";
import "../index.css";
import { about } from "../constants/index";
import CountUp from "./CountUp";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import Deployment from "../assets/about-modal/deployment1.png";
import Support from "../assets/about-modal/support1.png";
import require2 from "../assets/about-modal/requirement3.png";
import develop2 from "../assets/about-modal/development5.png";

const About = () => {
  const [isVisiable, setIsVisiable] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const listenToScroll = () => {
    let heightToHidden = 110;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsVisiable(true);
    } else {
      setIsVisiable(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);
  const arr = [
    "requirement analysis & planning",
    "design & development",
    "testing & deployment",
    "support & maintenance",
  ];
  return (
    <>
      <motion.div
        className="w-screen -mt-12 sm:-mt-6 relative z-0"
        id="About"
        ref={ref}
        variants={{
          hidden: { opacity: 1, y: 0 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="visible"
        animate={mainControls}
        transition={{ duration: 1.6 }}
      >
        <motion.div
          className="bg-[#C0E7F1] pt-10 "
          ref={ref}
          variants={{
            hidden: { opacity: 1, y: 0 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="visible"
          animate={mainControls}
        >
          <div className="max-w-[100%] mx-auto grid grid-cols-1 xxmd:grid-cols-2 gap-2 mt-2">
            <motion.div
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 1.6 }}
              className="order-1 w-full"
            >
              <div className="flex justify-start md:mx-20 mx-4 md:mb-2 w-full">
                <span className="md:py-4  md:mt-1 font-poppins lmd:text-[48px] font-semibold text-[30px] bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  Why Choose MappOptimist?
                </span>
              </div>
              <h2 className="mt-8 w-[90%] text-[18px] md:mx-20  mx-6 font-poppins200 leading-2 xxmd:text-[30px] xxmd:leading-normal">
                Experience the Mappoptimist advantage: tailor-made IT solutions
                seamlessly integrate with your business, empowering growth and
                innovation. Trust our expert team for personalized support and
                impactful results, driving your success in the digital era.
              </h2>
            </motion.div>
            <motion.div
              className="flex flex-wrap  justify-center md:justify-end pr-12 gap-4 w-full md:mx-auto order-2 mb-10 xxmd:w-[90%] mx-6  mt-4 "
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 1.6 }}
            >
              {about.map((item) => (
                <div
                  className="flex justify-center font-poppins500 xxmd:text-[36.75px] flex-col h-48 rounded-xl bg-white w-[240px] "
                  key={item.id}
                >
                  <div className="text-[25px] font-medium xxmd:text-[36.75px] xxmd:leading-[55.12px] text-[#1AB2DD] mx-4">
                    {isVisiable && (
                      <div className="flex font-bold text-5xl ">
                        <CountUp start={0} end={item.number} /> {item.symbol}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-start  mx-4">
                    <p className="text-[25px] font-medium text-black mt-4 ">
                      {item.title}
                    </p>
                    <p className=" leading-tight my-2 text-[9px] capitalize font-medium xxmd:text-[12.25px] xxmd:leading-[18.37px] ">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </motion.div>
            <motion.div
              className="w-full h-full overflow-visible mt-10 md:mt-3 order-2 xxmd:order-3 xxmd:col-span-2 xxmd:mb-10 mb-6 z-9 relative xxmd:gap-10 font-poppins500"
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 1.6 }}
            >
              <div class="w-screen h-auto md:px-14 px-0">
                <div class="md:flex max-w-full h-auto justify-center items-center md:space-x-5 space-y-16 md:space-y-0 md:mx-0 mx-10">
                  <div className=" justify-self-center md:my-4 md:pb-4  relative  flex flex-col align-top bg-white justify-center  items-center  min-h-[180px] w-full md:h-[180px] md:w-[250px]  border rounded-xl overflow-visible shadow-lg transition duration-300">
                    <img
                      src={require2}
                      className="h-[8rem] w-1/3 object-contain  relative -top-20"
                      alt=""
                    ></img>
                    <div className=" text-[#146AA1] flex flex-col  text-[14px] md:text-[18px] absolute md:top-10 top-1/2 text-center leading-tight md:py-6 font-poppins">
                      <h1>Requirement Analysis </h1>
                      <h1>&</h1>
                      <h1>Planning</h1>
                    </div>
                  </div>
                  <div className=" justify-self-center md:my-4 md:pb-4  relative  flex flex-col align-top bg-white justify-center  items-center  h-[180px] w-full md:h-[180px] md:w-[250px]  border rounded-xl overflow-visible shadow-lg transition duration-300">
                    <img
                      src={develop2}
                      className="h-[8rem] w-1/3 object-contain  relative -top-20"
                      alt=""
                    ></img>
                    <div className=" text-[#146AA1] flex flex-col  text-[14px] md:text-[18px] absolute md:top-10 top-1/2 text-center leading-tight md:py-6 font-poppins">
                      <h1>Design </h1>
                      <h1>&</h1>
                      <h1>Development</h1>
                    </div>
                  </div>{" "}
                  <div className=" justify-self-center md:my-4 md:pb-4  relative  flex flex-col align-top bg-white justify-center  items-center  h-[180px] w-full md:h-[180px] md:w-[250px]  border rounded-xl overflow-visible shadow-lg transition duration-300">
                    <img
                      src={Deployment}
                      className="h-[8rem] w-1/3 object-contain  relative -top-20"
                      alt=""
                    ></img>
                    <div className=" text-[#146AA1] flex flex-col  text-[14px] md:text-[18px] absolute md:top-10 top-1/2 text-center leading-tight md:py-6 font-poppins">
                      <h1>Testing</h1>
                      <h1>&</h1>
                      <h1>Deployment</h1>
                    </div>
                  </div>{" "}
                  <div className=" justify-self-center md:my-4 md:pb-4  relative  flex flex-col align-top bg-white justify-center  items-center  h-[180px] w-full md:h-[180px] md:w-[250px]  border rounded-xl overflow-visible shadow-lg transition duration-300">
                    <img
                      src={Support}
                      className="h-[8rem] w-1/3 object-contain  relative -top-20"
                      alt=""
                    ></img>
                    <div className=" text-[#146AA1] flex flex-col  text-[14px] md:text-[18px] absolute md:top-10 top-1/2 text-center leading-tight md:py-6 font-poppins">
                      <h1>Support</h1>
                      <h1>&</h1>
                      <h1>maintenance</h1>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default About;
