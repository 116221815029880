import * as Yup from "yup";

export const ContactUsSchema = (isQuote) =>
  Yup.object().shape({
    ...(isQuote && {
      services: Yup.string().required("Required*"),
    }),
    ...(!isQuote && {
      technology: Yup.string().required("Required*"),
    }),
    name: Yup.string().required("Required*"),
    email: Yup.string().email().required("Required*"),
    contact: Yup.number().required("Required*"),
    budget: Yup.string().required("Required*"),
    description: Yup.string().required(
      `${isQuote ? "Required*" : "Required*"}`
    ),
  });

  export const hireDeveloperSchema = () =>
    Yup.object().shape({
      name: Yup.string().required("Required*"),
      email: Yup.string().email().required("Required*"),
      phone: Yup.number().required("Required*"),
      requirement: Yup.string().required("Required*"),
    });