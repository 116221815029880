import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import logo2 from "../assets/navbar/logo2.png";
import eclips1 from "../assets/navbar/eclips1.webp";
import eclips2 from "../assets/navbar/eclips2.webp";
import eclips3 from "../assets/navbar/eclips3.webp";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker ";
import Logo from "../assets/navbar/logo.webp";
import { AiOutlineMenu } from "react-icons/ai";
import TopNavbar from "./topNavbar";
import HireModal from "./HireModal";
import { useLocation } from "react-router-dom";
import ServicesDropdown from "./servicesDropdown";
import { isMobile } from "react-device-detect";
import { scroller } from "react-scroll";

const Navbar = ({ isStickyHeader }) => {
  const gaEventTracker = useAnalyticsEventTracker("Navbar");
  const [toggle, setToggle] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const { pathname, hash } = location;
  let activeTimer = null;

  useEffect(() => {
    const key = sessionStorage.getItem("path-key");
    if (pathname === "/" && key !== pathname) {
      sessionStorage.setItem("path-key", pathname);
      if (hash === "#Services") {
        scrollFun("Services");
      }
      if (hash === "#blogs") {
        scrollFun("blogs");
      }
      if (hash === "#Contact-Us") {
        scrollFun("Contact-Us");
      }
    } else {
      setActiveTab(pathname);
      sessionStorage.setItem("path-key", pathname);
    }
    if (pathname === "/") {
      let flag = pathname;
      if (hash === "#Services") {
        flag = "#Services";
      }
      if (hash === "#blogs") {
        flag = "#blogs";
      }
      if (hash === "#Contact-Us") {
        flag = "#Contact-Us";
      }
      setActiveTab(flag);
    } else {
      if (pathname?.includes("hire-developer")) {
        setActiveTab("hire-developer");
      } else if (pathname?.includes("blog-details")) {
        setActiveTab("blog-details");
      } else if (pathname?.includes("services-page")) {
        setActiveTab("services-page");
      } else {
        setActiveTab(pathname);
      }
    }
  }, [hash, location, pathname]);

  const scrollFun = (id) => {
    activeTimer = setTimeout(() => {
      scrollToTarget(id);
    }, 2000);
  };

  useEffect(() => {
    setIsSticky(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isStickyHeader) {
      setIsSticky(true);
    }
    window.scrollTo(0, 0);
  }, [isStickyHeader]);

  const listenScrollEvent = () => {
    const scrollPosition = window?.scrollY;
    const sections = ["Hero-Section", "Services", "blogs", "Contact-Us"];
    let active = null;

    sections.forEach((sectionId) => {
      const section = document?.getElementById(sectionId);
      if (
        section?.offsetTop - 120 <= scrollPosition &&
        section?.offsetTop + section?.offsetHeight - 120 > scrollPosition
      ) {
        active = sectionId;
      }
    });
    console.log("active=>", active);
    if (active) {
      if (active === "Hero-Section") {
        setActiveTab(`/`);
      } else {
        setActiveTab(`#${active}`);
      }
    }
    if (!isStickyHeader) {
      const { pathname } = location;
      if (pathname === "/" && !isMobile) {
        window.scrollY > 965 ? setIsSticky(true) : setIsSticky(false);
      } else {
        window.scrollY > 45 ? setIsSticky(true) : setIsSticky(false);
      }
      if (isMobile && window.scrollY > 10) {
        setToggle(false);
        setIsOpen(true);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTarget = (id) => {
    clearTimeout(activeTimer);
    // const targetDiv = document.getElementById(id);
    // if (targetDiv) {
    //   targetDiv.scrollIntoView(false);
    // }
    scroller.scrollTo(id, {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuint",
      offset: -90,
    });
  };
  return (
    <>
      <div
        className={`w-screen z-50
        ${
          isSticky ? "sticky-from-top scroll-smooth" : ""
        } bg-gradient-to-r from-primary to-secondary absolute top-0 left-0 right-0`}
      >
        {!isSticky ? <TopNavbar /> : null}
        <nav
          className={
            "flex items-center mx-auto w-[100%] relative top-0 left-0 right-0 sm:relative z-50 text-black py-[8px]"
          }
        >
          <ul className="list-none w-full hidden sm:flex flex-row  lmd:gap-2 lmd:text-[12px] md:gap-4 self-center z-50 relative">
            <Link
              to={`/`}
              className="flex items-center cursor-pointer justify-start gap-1 sm:w-[15%] sm:justify-start bg-transparent z-50 ml-[5%]"
            >
              <img
                src={Logo}
                alt="logo"
                className={`${
                  toggle === true
                    ? "hidden"
                    : "w-[15%] absolute sm:w-[35%] top-3 sm:relative sm:top-0 z-20 "
                }`}
                loading="lazy"
              />
              <p className="text-[18px] font-poppins cursor-pointer xmd:flex xmd:flex-col xmd:gap-0 hidden mdd:w-0 justify-center  w-64">
                <span className="text-white leading-none">MappOptimist</span>
                <span
                  className="text-[10px] opacity-[0.5] italic leading-tight pt-1
                   text-white"
                >
                  AI First: Leading the Future of Innovation
                </span>
              </p>
            </Link>
            <div className="flex justify-end w-full mr-[5%] items-center gap-4">
              <Link
                className="relative z-10 group"
                to="/"
                smooth={true}
                offset={100}
                duration={1000}
                onClick={() => {
                  scrollToTarget("Hero-Section");
                  gaEventTracker("nav-Home", "Cliked on nav from HomePage");
                }}
              >
                <li
                  className={`text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                   relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                     activeTab === "/"
                       ? "bg-white text-[#020449]"
                       : "text-white"
                   }`}
                >
                  Home
                </li>
              </Link>
              <NavLink
                className="group relative z-10"
                to={`/about-us`}
                onClick={() => {
                  gaEventTracker("nav-About", "Cliked on nav from HomePage");
                }}
              >
                <li
                  className={`text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                   relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                     activeTab === "/about-us"
                       ? "bg-white text-[#020449]"
                       : "text-white"
                   }`}
                >
                  About
                </li>
              </NavLink>
              <Link
                className="z-50 group"
                smooth={true}
                to={`/#Services`}
                offset={100}
                duration={1000}
                onClick={() => {
                  scrollToTarget("Services");
                  // gaEventTracker("nav-Home", "Cliked on nav from HomePage");
                }}
              >
                <div>
                  <li
                    className={`hoverable text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                   relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                     ["services-page", "#Services"]?.includes(activeTab)
                       ? "bg-white text-[#020449]"
                       : "text-white"
                   }`}
                  >
                    Services
                    <ServicesDropdown />
                  </li>
                </div>
              </Link>
              <Link
                className="group relative z-10"
                to={`/#blogs`}
                smooth={true}
                offset={100}
                duration={1000}
                onClick={() => {
                  scrollToTarget("blogs");
                  gaEventTracker(
                    "nav-Portfolio",
                    "Cliked on nav from HomePage"
                  );
                }}
              >
                <li
                  className={`text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                  relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                    ["blog-details", "#blogs", "/all-blogs"]?.includes(
                      activeTab
                    )
                      ? "bg-white text-[#020449]"
                      : "text-white"
                  }`}
                >
                  Blogs
                </li>
              </Link>

              <div className="group">
                <li
                  className={`hoverable text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                   relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                     activeTab === "hire-developer"
                       ? "bg-white text-[#020449]"
                       : "text-white"
                   }`}
                >
                  Hire Us
                  <HireModal />
                </li>
              </div>
              <Link
                className="group relative z-10"
                to={`/#Contact-Us`}
                smooth={true}
                offset={10}
                duration={1000}
                onClick={() => {
                  scrollToTarget("Contact-Us");
                  gaEventTracker(
                    "nav-Contact-US",
                    "Cliked on nav from HomePage"
                  );
                }}
              >
                <li
                  className={`text-[20px] rounded-full  cursor-pointer leading-[30px] font-poppins
                   relative py-1 px-3 hover:bg-white group-hover:text-[#020449] ${
                     activeTab === "#Contact-Us"
                       ? "bg-white text-[#020449]"
                       : "text-white"
                   }`}
                >
                  Contact Us
                </li>
              </Link>
            </div>
          </ul>

          <div
            className={
              " bg-transparent  bg-none  sm:hidden flex flex-1 justify-between order-1 w-[100%] rounded-lg relative z-40 transition-all duration-[1000ms] overflow-hidden h-auto linear"
            }
          >
            <img
              src={eclips1}
              alt="eclips-1"
              className={`${
                toggle === false
                  ? "hidden"
                  : "absolute top-0 right-0 rounded-lg -z-1"
              }`}
            />
            <img
              src={eclips2}
              alt="eclips-2"
              className={`${
                toggle === false
                  ? "hidden"
                  : "absolute top-[50%] left-[50%] rounded-lg -z-1"
              }`}
            />
            <img
              src={eclips3}
              alt="eclips-3"
              className={`${
                toggle === false
                  ? "hidden"
                  : "absolute bottom-0 -left-2  -z-1 rounded-lg"
              }`}
            />
            <button
              className="flex flex-col gap-1 h-12 w-16 rounded justify-center items-center group z-50"
              onClick={() => {
                setIsOpen(!isOpen);
                setToggle(!toggle);
              }}
            >
              <AiOutlineMenu className="text-white" />
            </button>
            <div
              className={`${
                !toggle ? "opacity-0 h-0" : "opacity-100 h-fit"
              }  justify-center items-center mx-auto transition-all duration-[2000ms] linear`}
            >
              <ul className="list-none flex justify-between items-start flex-col gap-4 mb-12 w-full mt-12 transform transition duration-1000 ease-linear">
                <Link
                  to={`/`}
                  smooth={true}
                  offset={100}
                  duration={1000}
                  onClick={() => {
                    setToggle(!toggle);
                    setIsOpen(!isOpen);
                    gaEventTracker("nav-Home", "Cliked on nav from HomePage");
                  }}
                >
                  <li
                    className="text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
               hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                    onClick={() => {
                      scrollToTarget("Hero-Section");
                      setToggle(!toggle);
                      setIsOpen(!isOpen);
                    }}
                  >
                    Home
                  </li>
                </Link>
                <NavLink
                  to={`/about-us`}
                  onClick={() => {
                    setToggle(!toggle);
                    setIsOpen(!isOpen);
                    gaEventTracker("nav-About", "Cliked on nav from HomePage");
                  }}
                >
                  <li
                    className="text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
               hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                    onClick={() => {
                      setToggle(!toggle);
                      setIsOpen(!isOpen);
                    }}
                  >
                    About
                  </li>
                </NavLink>

                <Link
                  className="z-50 group"
                  to={`/#Services`}
                  smooth={true}
                  offset={100}
                  duration={1000}
                  onClick={() => {
                    setToggle(!toggle);
                    setIsOpen(!isOpen);
                    gaEventTracker(
                      "nav-Portfolio",
                      "Cliked on nav from HomePage"
                    );
                  }}
                >
                  <div>
                    <li
                      className="hoverable relative  text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
               hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                      onClick={() => {
                        setToggle(!toggle);
                        setIsOpen(!isOpen);
                      }}
                    >
                      Services
                      <ServicesDropdown />
                    </li>
                  </div>
                </Link>
                <Link
                  to={`/#blogs`}
                  smooth={true}
                  offset={100}
                  duration={1000}
                  onClick={() => {
                    setToggle(!toggle);
                    setIsOpen(!isOpen);
                    gaEventTracker(
                      "nav-Expertise",
                      "Cliked on nav from HomePage"
                    );
                  }}
                >
                  <li
                    className="text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
               hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                    onClick={() => {
                      setToggle(!toggle);
                      setIsOpen(!isOpen);
                    }}
                  >
                    Blogs
                  </li>
                </Link>
                <Link
                  smooth={true}
                  to={`/hire-developer-mobile`}
                  offset={100}
                  duration={1000}
                  onClick={() => {
                    gaEventTracker("nav-Home", "Cliked on nav from HomePage");
                  }}
                >
                  <div>
                    <li
                      className="text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
                      hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                    >
                      Hire Us
                    </li>
                  </div>
                </Link>
                <Link
                  to={`/#Contact-Us`}
                  smooth={true}
                  offset={100}
                  duration={1000}
                  onClick={() => {
                    setToggle(!toggle);
                    setIsOpen(!isOpen);
                  }}
                >
                  <li
                    className="text-[20px] cursor-pointer font-poppins leading-[30px] py-1 px-3 rounded-full
               hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white transition duration-300 z-10"
                    onClick={() => {
                      setToggle(!toggle);
                      setIsOpen(!isOpen);
                      gaEventTracker(
                        "nav-Contact-US",
                        "Cliked on nav from HomePage"
                      );
                    }}
                  >
                    Contact Us
                  </li>
                </Link>
              </ul>
            </div>
            <div
              className={`${toggle === false ? "hidden" : "w-16 h-12 flex "}`}
            >
              <Link
                to={`/#Home`}
                className="flex"
                onClick={() => {
                  setToggle(false);
                  setIsOpen(true);
                  window.scrollTo(0, 0);
                  gaEventTracker("nav-Logo", "Cliked on nav from HomePage");
                }}
              >
                <img
                  src={logo2}
                  alt="logo"
                  className={`${
                    toggle === false ? "hidden" : "w-[80%] block z-10"
                  }`}
                />
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
