import React from "react";
import "react-multi-carousel/lib/styles.css";
import { agencies } from "../constants";
import { motion } from "framer-motion";

const AgenciesSlider = () => {
  const duplicatedSlides = [
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
    ...agencies,
  ];

  return (
    <div
      className="relative h-full overflow-hidden py-4  mx-auto mb-10  md:mb-0 "
      style={{ width: "100%" }}
    >
      <motion.div
        className="flex"
        animate={{
          x: ["0%", "-1000%"],
          transition: {
            ease: "linear",
            duration: 250,
            repeat: Infinity,
          },
        }}
      >
        {duplicatedSlides.map((slide, index) => (
          <div
            key={index}
            className="flex-shrink-0  "
            style={{ width: `${200 / agencies?.length}%` }}
          >
            <div className="flex items-center justify-center h-full">
              <img
                src={slide.image}
                alt={slide.name}
                className="mx-auto sm:w-[50%] xsm:w-[70%] xxmd:w-[90%] midxmd:w-[60%] md:h-[80%] object-contain"
              />
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AgenciesSlider;
