import React from "react";
import "../index.css";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import MySwiperComponent from "./MySwiperComponent";
import { useNavigate } from "react-router";
const Blogs = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <>
      <div
        className="relative object-contain bg-no-repeat w-screen agencies bg-[#F1FBFC] agencies z-10 scroll-mt-24"
        id="blogs"
        name="blogs"
      >
        <motion.div
          className="midxs:block w-[80%] mx-auto z-30"
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 2 }}
        >
          <div className="flex justify-start  ml-2 md:-ml-14  md:mb-10 mt-6">
            <span className="text-center md:py-3 font-poppins lmd:text-[48px] font-semibold text-[30px] bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Blogs
            </span>
          </div>
        </motion.div>
        <MySwiperComponent />
        <div
          onClick={() => navigate("/all-blogs")}
          className="flex justify-center  items-center mr-10 sm:mr-24 lmd:mr-0 mx-auto gap-2 md:flex-row md:justify-center md:mx-0"
        >
          <button className=" w-[200px] flex items-center mb-10 gap-4 justify-center bg-gradient-to-r from-primary to-secondary py-[6px] px-[2px] rounded-full cursor-pointer text-[22px] font-medium text-white font-poppins500 ml-12 ">
            View All
          </button>
        </div>
      </div>
    </>
  );
};

export default Blogs;
