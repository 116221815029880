import { useState } from "react";
import dashboardService from "../network/services/v1/dashboard-service/dashboard-service";

function useFetchBlogs() {
  const [blogList, setBlogList] = useState([]);
  const [totalPage, setTotalPage] = useState(10);
  const searchBlogs = (query = "") => {
    getBlogs(query);
  };

  const getBlogs = async (query) => {
    try {
      const res = await dashboardService.getBlogs(query);

      if (res?.data?.data) {
        setBlogList(res?.data?.data);
        setTotalPage(res?.data?.totalPage);
      }
    } catch (error) {
      setBlogList([]);
    }
  };

  return { blogList, searchBlogs, totalPage };
}

export default useFetchBlogs;
