import React, { useRef, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import WebDevelopment from "../assets/Services/software-developer-6521720.jpg";
import { motion, useInView, useAnimation } from "framer-motion";
import DeveloperModal from "../components/developerModal";
import { hireDeveloper } from "../constants/hireDeveloper";
import ContactSection from "../components/ContactSection";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

const HireDeveloperDetails = () => {
  const ref = useRef(null);
  const { developerId } = useParams();
  const [devDetail, setDevDetail] = useState();
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  console.log("isMobile=>", isMobile);
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const handleHireDeveloper = (path) => {
    window.open(`/hire-developer/${path}`, "_blank");
  };

  const getDetails = () => {
    const devDetail = hireDeveloper.filter(
      (item) => developerId === item.developerProfile
    );
    setDevDetail(devDetail);
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex w-full md:h-1/2  relative  justify-center items-center">
        {isMobile ? (
          <div className="w-full h-[1200px] md:h-[800px] object-cover opacity-90 bg-gradient-to-r from-primary to-secondary" />
        ) : (
          <img
            src={WebDevelopment}
            alt=""
            className="w-full h-[1200px] md:h-[800px] object-cover opacity-90 bg-gradient-to-r from-primary to-secondary"
          />
        )}
        <div className="flex absolute  flex-col justify-center items-center px-10 md:mt-32 mt-20">
          <div className="w-full md:w-[100%] flex flex-col justify-center h-fit mx-auto  md:flex-row lmd:justify-between items-center relative z-40 inset-0 mb-10 sm:mb-10 md:mb-10">
            <motion.div
              className="md:mt-0 md:order-2 flex items-center justify-center w-[300px] md:w-[75%]"
              ref={ref}
              variants={{
                hidden: { opacity: 0, x: 100 },
                visible: { opacity: 1, x: 0 },
              }}
              animate={mainControls}
              transition={{ duration: 1.9 }}
            >
              <DeveloperModal />
            </motion.div>

            <motion.div
              className="flex flex-col w-full items-start justify-start md:ml-20 mt-4"
              ref={ref}
              variants={{
                hidden: { opacity: 0, x: -100 },
                visible: { opacity: 1, x: 0 },
              }}
              animate={mainControls}
              transition={{ duration: 1.9 }}
            >
              <div className="md:w-full flex flex-col justify-center ml-4 md:ml-0">
                <div className="text-[#FCE543]  -z-1 font-poppins text-2xl md:text-[3rem] font-bold md:leading-relaxed">
                  <h1>{devDetail?.[0]?.title}</h1>
                </div>
                <div className="text-white  -z-1 font-poppins text-2xl md:text-[1rem] font-bold mt-4">
                  <h1>{devDetail?.[0]?.subTitle}</h1>
                </div>
                <span className="text-white  text-lg leading-loose my-4 font-light w-full md:line-clamp-none line-clamp-4">
                  {devDetail?.[0]?.description}
                </span>
                <button className=" w-full md:w-1/2 flex items-center mt-2 gap-3 justify-center bg-white py-[8px] px-[8px] rounded-full cursor-pointer text-[22px] font-medium font-poppins500 border">
                  <span className="font-poppins bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                    Hire Individual
                  </span>
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div>
        <motion.div
          className="w-screen  relative z-0 md:py-20 py-6"
          id="About"
          ref={ref}
          variants={{
            hidden: { opacity: 1, y: 0 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="visible"
          animate={mainControls}
          transition={{ duration: 1.6 }}
        >
          <div
            className="flex flex-col justify-start items-center md:pb-10 pb-2 "
            id="Services"
          >
            <h1 className="py-2 text-5xl md:text-6xl font-semibold font-poppins bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Our Services
            </h1>
          </div>
          <div className="flex flex-wrap col-span-3 justify-center items-center w-full md:px-20 px-8 md:gap-20 gap-4">
            {devDetail?.[0]?.services?.map((i, index) => (
              <div className="bg-white  md:w-[340px] h-auto  rounded-md  ">
                <div className="flex flex-col justify-center items-center md:space-y-6  my-10">
                  <img src={i.icon} alt="SEO" className="w-28 h-28 " />
                  <h1 className="text-[20px] font-poppins text-center">
                    {i?.name}
                  </h1>
                  <h1 className="text-[16px] leading-snug font-light text-center">
                    {i.description}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>

      <div
        className="h-auto w-full inline-block  justify-center align-center bg-[#E1F4F8] rounded-lg text-left overflow-hidden transform transition-all md:p-12 md:px-20"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="flex justify-center font-semibold font-poppins mb-10 mx-2 items-center text-center">
          Other Developers you can Hire from mappOptimist
        </div>
        <div className="md:flex  md:flex-wrap justify-center items-center">
          {hireDeveloper?.map((i, index) => (
            <div
              key={index}
              className=" bg-[#E1F4F8] mx-10 md:w-[30%] md:mx-auto h-auto z-30 relative overflow-hidden  px-4 py-2 md:pl-24"
            >
              <div
                className="flex text-[16px] font-extralight hover:text-[#00AAC5] text-left text-black underline"
                onClick={() => handleHireDeveloper(i.developerProfile)}
              >
                {i.developerProfile}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContactSection />
    </>
  );
};

export default HireDeveloperDetails;
