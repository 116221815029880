import getResposneAccordingToStatus from "../response-handlers"

export const getAxiosRequestInterceptors = (config) => {
  return config
}

export const getAxiosResposeInterceptors = (resposne) => {
  /**
   * We can attach logger here and we can modulate response  as well here 
   * example in case
   */
  return getResposneAccordingToStatus(resposne)
}
