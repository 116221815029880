"use client";
import React from "react";
import BlogCard from "../components/BlogCard";
import BottomPagination from "../components/Pagination";
import Navbar from "../components/Navbar";
import useFetchBlogs from "../hooks/useFetchBogs";
import {blogsData} from "../constants";

const AllBlogPage = ({params}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const {blogList, searchBlogs, totalPage} = useFetchBlogs();
  let timerHandler = null;
  let pageSize = 10;

  React.useEffect(() => {
    fetcBlogs(currentPage, search);
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
    fetcBlogs(page, search);
  };

  const fetcBlogs = (page, search) => {
    let query = `page=${ page }&pageSize=${ pageSize }&search=${ search }`;
    searchBlogs(query);
  };
  const onTextChange = (value) => {
    setSearch(value);
    clearTimeout(timerHandler);
    timerHandler = setTimeout(() => {
      fetcBlogs(currentPage, search);
    }, 500);
  };

  return (
    <>
      <div className="w-screen  bg-white">
        <Navbar isStickyHeader={true} />
        <div className="md:pt-0">
          <div className="max-w-screen-xl md:flex items-center justify-between mx-auto my-4 align-bottom md:pt-20">
            <div className="w-full">
              <div className="md:flex md:mx-auto md:justify-between pt-10 mx-5">
                <h1 className="font-bold text-black text-base md:text-[30px] font-poppins  py-2 md:px-5">
                  <span className="block xl:inline bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent  text-[30px] md:text-[48px]">
                    {" "}
                    Blogs
                  </span>
                </h1>
                {/* <div className="flex items-end md:mx-2 space-x-3 md:px-4">
                  <div className="flex md:order-2">
                    <div className="relative w-full min-w-[300px]">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                        <span className="sr-only">Search icon</span>
                      </div>
                      <input
                        type="text"
                        id="search-navbar"
                        className="w-full block py-2 px-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          onTextChange(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative z-10 bg-white pt-2">
            <div className="max-w-screen-xl mx-auto justify-center items-center">
              <div className="flex flex-wrap justify-center">
                {blogsData?.map((item) => (
                  <div className=" bg-[#FFF] mx-5 md:w-[30%] md:mx-auto h-auto z-30 relative overflow-hidden shadow-[10px_20px_50px_-10px_rgba(0,0,0,0.6)] rounded-md my-6 p-4">
                    <BlogCard key={item?._id} blog={item} />
                  </div>
                ))}
              </div>
              <div className="flex md:justify-end mt-4 pb-8 justify-center">
                <BottomPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => {
                    onPageChange(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBlogPage;
