import aiIcon from "../assets/Services/Ai.png";
import ServiceSubImg from "../assets/Services/servicesSubIMg3.png";
import WebDevelopment from "../assets/Services/software-developer-6521720.jpg";
import SubApp from "../assets/Services/serviceAppSubImg.jpeg";
import UisubImg from "../assets/Services/uiSubImg.webp";
import DigSubImg from "../assets/Services/digiSubImg5.png";
import cyberSecuritySubImg from "../assets/Services/cybersecuritySubImg.png";
import cloudSubImg from "../assets/Services/cloudSubImg.png";
import AiSubImg from "../assets/Services/aiSubImg.jpeg";
import WebIcon from "../assets/Services/webDevelopement.png";
import AppIcon from "../assets/Services/app-development (1).png";
import UIIcon from "../assets/Services/ux-design.png";
import digitalIcon from "../assets/Services/digital-marketing.png";
import cyberIcon from "../assets/Services/cyberSecurity.png";
import cloudIcon from "../assets/Services/cloud-computing.png";
import ItStaffingIcon from "../assets/Services/ItStaffingIcon.png";
import techno1 from "../assets/Services/techno-1.png";
import techno2 from "../assets/Services/techno-2.png";
import techno3 from "../assets/Services/techno-3.png";
import techno4 from "../assets/Services/techno-4.png";
import techno5 from "../assets/Services/techno-5.png";
import techno6 from "../assets/Services/techno-6.png";
import techno7 from "../assets/Services/techno-7.png";
import techno8 from "../assets/Services/techno-8.png";
import techno9 from "../assets/Services/techno-9.png";
import techno10 from "../assets/Services/techno-10.png";
import techno11 from "../assets/Services/techno-11.png";
import techno12 from "../assets/Services/techno012.png";
import Python from "../assets/Services/python.png";
import GoogleCloud from "../assets/Services/googleCloud.png";
import IBMWatson from "../assets/Services/IBMWatson.png";
import Keras from "../assets/Services/Keras.webp";
import OpenAi from "../assets/Services/openAi.webp";
import Kalilinux from "../assets/Services/kaliLinux.jpeg";
import WireShark from "../assets/Services/wireShark.png";
import Splunk from "../assets/Services/splunk.jpeg";
import Metasploit from "../assets/Services/metasploit.png";
import Aws from "../assets/Services/aws.jpeg";
import TensorFlow from "../assets/Services/TensorFlow.png";
import GoogleAnalytics from "../assets/Services/googleanalytics.png";
import HubSpot from "../assets/Services/HubSpot.png";
import MailChimp from "../assets/Services/mailChimp.png";
import Hootsuite from "../assets/Services/Hootsuite.png";
import SalesForce from "../assets/Services/Salesforce.webp";
import Marketo from "../assets/Services/marketo.png";
import semRush from "../assets/Services/semrush.png";
import ahrefs from "../assets/Services/ahrefs.webp";
import linkedin from "../assets/Services/linkedin.jpeg";
import indeed from "../assets/Services/indeed.png";
import WorkDay from "../assets/Services/workDay.png";
import oracle from "../assets/Services/oracle.png";

export const servicesList = [
  {
    id: 1,
    icon: aiIcon,
    title: "AI-ML",
    mainImg: WebDevelopment,
    subImg: AiSubImg,
    subTitle: "Choose The Best AI/ML Development Company",
    shortDescription:
      "AI/ML services revolutionize automation, bolster cybersecurity, and enhance performance through predictive analytics and anomaly detection.",
    description:
      "Artificial Intelligence (AI) embodies the frontier of technological advancement, a realm where machines emulate human cognitive functions to perceive, understand, and respond to the world around them. In this expansive landscape, AI acts as a catalyst, driving innovation and reshaping industries by harnessing the power of technology to solve complex problems. At the heart of AI lies Machine Learning (ML), a dynamic subset that propels machines towards autonomy and proficiency through experiential learning. Unlike traditional programming, ML equips machines with the ability to glean insights from vast datasets, adapt, and improve their performance over time. Through iterative analysis and refinement, ML algorithms unlock new realms of efficiency and intelligence, revolutionizing how we interact with technology.",
    whyChooseUs:
      "Experience matters in the realm of Artificial Intelligence and Machine Learning, and MappOptimist Technologies stands tall as a beacon of expertise with nearly a decade of mastery in these cutting-edge domains. With a profound understanding of the intricacies of AI/ML technologies, Harnessing the power of AI and ML, MappOptimist empowers businesses to unlock new possibilities, solve complex problems, and drive unprecedented growth.",
    whyChooseUsSubTitle:
      "With a wealth of experience in implementing AI/ML solutions, MappOptimist possesses the expertise to navigate the nuances of these technologies, delivering tailored solutions that align with business objectives and drive tangible results. In a world where AI/ML hold the keys to unlocking competitive advantage, MappOptimist emerges as the trusted partner of choice, offering unparalleled technical prowess, strategic guidance, and unwavering commitment to client success",
    technology: [
      {
        id: 1,
        name: "Python",
        image: Python,
      },
      {
        id: 2,
        name: "TensorFlow",
        image: TensorFlow,
      },
      {
        id: 3,
        name: "AWS",
        image: Aws,
      },
      {
        id: 4,
        name: "Microsoft Azure",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      },
      {
        id: 5,
        name: "Google Cloud",
        image: GoogleCloud,
      },
      {
        id: 6,
        name: "IBM Watson",
        image: IBMWatson,
      },
      {
        id: 7,
        name: "Keras",
        image: Keras,
      },
      {
        id: 8,
        name: "OpenAI GPT-4",
        image: OpenAi,
      },
      {
        id: 9,
        name: "Kali Linux",
        image: Kalilinux,
      },
      {
        id: 10,
        name: "Wireshark",
        image: WireShark,
      },
      {
        id: 11,
        name: "Splunk",
        image: Splunk,
      },
      {
        id: 12,
        name: "Metasploit",
        image: Metasploit,
      },
    ],
  },

  {
    id: 2,
    icon: WebIcon,
    mainImg: WebDevelopment,
    subImg: ServiceSubImg,
    title: "Web Development",
    shortDescription:
      "Crafting standout websites: custom designs, seamless UX, e-commerce solutions. Your vision, our expertise. Elevate your digital presence with us.",
    description:
      "An attractive website serves as your digital handshake, creating a lasting first impression that builds credibility and trust. It's your virtual storefront, engaging visitors with appealing visuals and intuitive design, making their experience memorable and encouraging action. In SEO, it reduces bounce rates and extends visit durations, boosting rankings. Effective visual storytelling simplifies complex ideas, enhancing message clarity and impact, while eye-catching content encourages social sharing, expanding your reach. Ultimately, an attractive website drives growth by enhancing visibility, engagement, and trust.",
    subTitle: "Choose The Best Web Development Company",
    whyChooseUs:
      "Embark on a digital journey with MappOptimist Technologies, where innovation meets experience. With over 13 years of experience, we craft digital experiences that captivate and convert. Imagine your website as a vibrant story, drawing visitors in with its visual allure and intuitive design. Our seamless navigation and captivating visuals ensure every user journey is unforgettable, urging visitors to linger and explore further. But we're not just about looks; we're about results.",
    whyChooseUsSubTitle:
      "Our websites aren't just visually stunning; they're SEO powerhouses, driving traffic and boosting rankings effortlessly. With clear, impactful communication and engaging storytelling, we transform complex ideas into compelling narratives that resonate with your audience. Choose MappOptimist Technologies as your web development partner and elevate your brand to new heights of success.",
    technology: [
      { id: 1, image: techno1, name: "jQuery" },
      { id: 2, image: techno2, name: "less" },
      { id: 3, image: techno3, name: "wordPress" },
      { id: 4, image: techno4, name: "syncFusion" },
      { id: 5, image: techno5, name: "html/css" },
      { id: 6, image: techno6, name: "react-native" },
      { id: 7, image: techno7, name: "node" },
      { id: 8, image: techno8, name: "xamarin" },
      { id: 9, image: techno9, name: "sass" },
      { id: 10, image: techno10, name: "bootstrap" },
      { id: 11, image: techno11, name: "flutter" },
      { id: 11, image: techno12, name: "Java" },
    ],
  },
  {
    id: 3,
    icon: AppIcon,
    title: "App Development",
    mainImg: WebDevelopment,
    subImg: SubApp,
    subTitle: "Choose The Best App Development Company",
    shortDescription:
      "Innovative mobile apps: Android, iOS, cross-platform. Tailored solutions, engaging UI/UX, robust functionality. Transform your ideas into reality.",
    description:
      "In this Digital Era, mobile applications stand tall as beacons of innovation, beckoning customers with personalized experiences and unparalleled accessibility. These digital marvels aren't just recommended; they're indispensable tools for growth, weaving seamless interactions, and unforgettable journeys. With their ability to engage, inspire, and connect, apps elevate brands to new heights of visibility and loyalty. From the convenience of e-commerce to the insights gleaned from data, each feature fuels the engine of progress, propelling businesses forward in the ever-evolving digital landscape. In this age of boundless possibilities, embracing the power of mobile applications isn't just a choice; it's a creative leap into the future of success.",
    whyChooseUs:
      "With over a decade of experience in app development for both iOS and Android platforms, MappOptimist Technologies is a trusted name in the IT industry. Our track record speaks volumes, as we've delivered countless successful projects that have earned us a stellar reputation.",
    whyChooseUsSubTitle:
      "When it comes to app development, our expertise shines through in every aspect, from initial concept to final delivery. Whether you're looking to create a cutting-edge mobile application or revamp an existing one, MappOptimist is your go-to partner for innovation and excellence. So why settle for ordinary when you can have extraordinary? Choose MappOptimist Technologies as your app development partner and embark on a journey of innovation, creativity, and success.",
    technology: [
      { id: 1, image: techno1, name: "jQuery" },
      { id: 2, image: techno2, name: "less" },
      { id: 3, image: techno3, name: "wordPress" },
      { id: 4, image: techno4, name: "syncFusion" },
      { id: 5, image: techno5, name: "html/css" },
      { id: 6, image: techno6, name: "react-native" },
      { id: 7, image: techno7, name: "node" },
      { id: 8, image: techno8, name: "xamarin" },
      { id: 9, image: techno9, name: "sass" },
      { id: 10, image: techno10, name: "bootstrap" },
      { id: 11, image: techno11, name: "flutter" },
      { id: 11, image: techno12, name: "Java" },
    ],
  },
  {
    id: 4,
    icon: cyberIcon,
    title: "Cyber Security",
    mainImg: WebDevelopment,
    subImg: cyberSecuritySubImg,
    subTitle: "Choose The Best App Development Company",
    shortDescription:
      "Protect your digital assets: secure data, safeguard systems, prevent cyber threats. Our cybersecurity solutions ensure your business is safe and secure.",
    description:
      "In today's digital age, cybersecurity is indispensable for safeguarding against a myriad of threats, including malware, ransomware, phishing, and data breaches. It involves implementing a range of technical measures, such as firewalls, encryption, and access controls, to protect digital assets and networks from unauthorized access and exploitation. Additionally, cybersecurity encompasses proactive strategies, such as threat intelligence gathering and user training, to mitigate risks and ensure a swift response to security incidents. Ultimately, investing in robust cybersecurity measures is essential to protect sensitive information, maintain trust, and uphold the integrity of digital ecosystems.",
    whyChooseUs:
      "With over a decade of mastery, MappOptimist is your trusted guardian in the digital realm, offering unparalleled expertise in cybersecurity. Using cutting-edge technologies like Behavioral Analytics, Blockchain, and Cloud Encryption, we ensure the safety of your digital assets. Our arsenal includes Context-Aware Security, Defensive Artificial Intelligence (AI), and Extended Detection and Response (XDR) to keep threats at bay.",
    whyChooseUsSubTitle:
      "We employ Manufacturer Usage Description (MUD) and adhere to the principles of Zero Trust to fortify your defences. At MappOptimist, we don't just protect your digital kingdom – we safeguard your peace of mind with innovation and dedication.",
    technology: [
      {
        id: 1,
        name: "Python",
        image: Python,
      },
      {
        id: 2,
        name: "TensorFlow",
        image: TensorFlow,
      },
      {
        id: 3,
        name: "AWS",
        image: Aws,
      },
      {
        id: 4,
        name: "Microsoft Azure",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      },
      {
        id: 5,
        name: "Google Cloud",
        image: GoogleCloud,
      },
      {
        id: 6,
        name: "IBM Watson",
        image: IBMWatson,
      },
      {
        id: 7,
        name: "Keras",
        image: Keras,
      },
      {
        id: 8,
        name: "OpenAI GPT-4",
        image: OpenAi,
      },
      {
        id: 9,
        name: "Kali Linux",
        image: Kalilinux,
      },
      {
        id: 10,
        name: "Wireshark",
        image: WireShark,
      },
      {
        id: 11,
        name: "Splunk",
        image: Splunk,
      },
      {
        id: 12,
        name: "Metasploit",
        image: Metasploit,
      },
    ],
  },
  {
    id: 5,
    icon: cloudIcon,
    title: "Cloud Computing",
    mainImg: WebDevelopment,
    subImg: cloudSubImg,
    subTitle: "Choose The Best App Development Company",
    shortDescription:
      "Reliable hosting services: domain registration, web hosting, SSL certificates, email hosting. Secure, scalable, and high-performance hosting solutions.",
    description:
      "Cloud computing is like renting services and resources over the internet instead of buying and maintaining them yourself. These services include things like storing data, running software, and using servers. Instead of having your own physical servers, you use servers owned and managed by a cloud service provider. This means you can access computing power and storage space whenever you need it, without having to worry about managing the hardware yourself. It's like having a virtual IT department that takes care of everything for you",
    whyChooseUs:
      "When it comes to cloud computing, MappOptimist Technologies reigns supreme. Renowned for their expertise and innovation in the field, MappOptimist stands at the forefront of cloud technology. With unparalleled solutions and a commitment to excellence, they empower businesses to harness the full potential of cloud computing for unparalleled scalability, efficiency, and agility.",
    whyChooseUsSubTitle:
      "Trust MappOptimist to lead the way in your cloud journey, delivering cutting-edge solutions tailored to your needs and propelling your business to new heights of success.",
    technology: [
      {
        id: 1,
        name: "Python",
        image: Python,
      },
      {
        id: 2,
        name: "TensorFlow",
        image: TensorFlow,
      },
      {
        id: 3,
        name: "AWS",
        image: Aws,
      },
      {
        id: 4,
        name: "Microsoft Azure",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      },
      {
        id: 5,
        name: "Google Cloud",
        image: GoogleCloud,
      },
      {
        id: 6,
        name: "IBM Watson",
        image: IBMWatson,
      },
      {
        id: 7,
        name: "Keras",
        image: Keras,
      },
      {
        id: 8,
        name: "OpenAI GPT-4",
        image: OpenAi,
      },
      {
        id: 9,
        name: "Kali Linux",
        image: Kalilinux,
      },
      {
        id: 10,
        name: "Wireshark",
        image: WireShark,
      },
      {
        id: 11,
        name: "Splunk",
        image: Splunk,
      },
      {
        id: 12,
        name: "Metasploit",
        image: Metasploit,
      },
    ],
  },
  {
    id: 6,
    icon: UIIcon,
    title: "UI-UX Design",
    mainImg: WebDevelopment,
    subImg: UisubImg,
    subTitle: "Choose The Best UI/UX Designers",
    shortDescription:
      "Captivating designs: user-centric interfaces, engaging experiences, seamless interactions. Elevate your brand with our UI/UX expertise.",
    description:
      "In the digital arena, where every click counts, a stellar UI/UX is your trusted guide through the maze of online presence. Think of it as your brand's secret sauce—a seamless blend of style and functionality that speaks directly to your audience's hearts. It's like crafting a delightful storybook where each page turn brings a new adventure, keeping users hooked from start to finish. With an engaging UI/UX, you're not just navigating the digital landscape; you're painting a masterpiece that leaves a lasting impression on anyone who stumbles upon it. So, why settle for ordinary when you can dazzle with extraordinary design? With a touch of creativity and a sprinkle of innovation, your digital presence can shine brighter than ever before.",
    whyChooseUs:
      "Unlock the magic of digital enchantment with MappOptimist Technologies. Our expertise in UI/UX design turns pixels into captivating experiences. Let us be your compass to online success, crafting delightful adventures for your users with every click. Choose MappOptimist for a tech hand that transforms digital dreams into reality.",
    whyChooseUsSubTitle: "",
    technology: [
      { id: 1, image: techno1, name: "jQuery" },
      { id: 2, image: techno2, name: "less" },
      { id: 3, image: techno3, name: "wordPress" },
      { id: 4, image: techno4, name: "syncFusion" },
      { id: 5, image: techno5, name: "html/css" },
      { id: 6, image: techno6, name: "react-native" },
      { id: 7, image: techno7, name: "node" },
      { id: 8, image: techno8, name: "xamarin" },
      { id: 9, image: techno9, name: "sass" },
      { id: 10, image: techno10, name: "bootstrap" },
      { id: 11, image: techno11, name: "flutter" },
      { id: 11, image: techno12, name: "Java" },
    ],
  },
  {
    id: 7,
    icon: digitalIcon,
    title: "Digital Marketing",
    mainImg: WebDevelopment,
    subImg: DigSubImg,
    subTitle: "Choose The Best App Development Company",
    shortDescription:
      "Strategic marketing solutions: SEO, SMM, SEM, content marketing, email campaigns. Boost your online presence and drive growth with our digital marketing services.",
    description:
      "In the world of digital marketing, partnering with a top-notch agency is like having a skilled guide for your brand's online journey. They're like expert navigators, helping you chart a course through the digital landscape to reach your goals. With their tools and strategies, they create campaigns that grab attention, inspire action, and set your brand apart from the crowd. So, why settle for average when you can team up with the best and watch your brand shine online?",
    whyChooseUs:
      "Enter the digital wonderland with MappOptimist Technologies, your go-to destination for enchanting digital experiences. Renowned in the IT market, MappOptimist specializes in crafting mesmerizing WordPress websites that captivate and inspire. But that's not all – armed with powerful SEO strategies and cutting-edge technological tools, they ensure your website stands out and shines brightly online. For a journey into digital magic and unparalleled online success, MappOptimist is your trusted guide.",
    whyChooseUsSubTitle: "",
    technology: [
      {
        id: 1,
        name: "Google Analytics",
        image: GoogleAnalytics,
      },
      {
        id: 2,
        name: "HubSpot",
        image: HubSpot,
      },
      {
        id: 3,
        name: "Mailchimp",
        image: MailChimp,
      },
      {
        id: 4,
        name: "Hootsuite",
        image: Hootsuite,
      },
      {
        id: 5,
        name: "Salesforce",
        image: SalesForce,
      },
      {
        id: 6,
        name: "Marketo",
        image: Marketo,
      },
      {
        id: 7,
        name: "SEMrush",
        image: semRush,
      },
      {
        id: 8,
        name: "Ahrefs",
        image: ahrefs,
      },
      {
        id: 9,
        name: "LinkedIn Recruiter",
        image: linkedin,
      },
      {
        id: 10,
        name: "Indeed",
        image: indeed,
      },
      {
        id: 11,
        name: "Workday",
        image: WorkDay,
      },
      {
        id: 12,
        name: "Oracle HCM",
        image: oracle,
      },
    ],
  },
  {
    id: 8,
    icon: ItStaffingIcon,
    title: "IT Staffing",
    mainImg: WebDevelopment,
    subImg: DigSubImg,
    subTitle: "Choose The Best IT Staffing Company",
    shortDescription:
      "Empowering your business with top-tier IT talent, ensuring  the expertise and innovation needed to drive success in a rapidly evolving technological landscape.",
    description:
      "In the world of digital marketing, partnering with a top-notch agency is like having a skilled guide for your brand's online journey. They're like expert navigators, helping you chart a course through the digital landscape to reach your goals. With their tools and strategies, they create campaigns that grab attention, inspire action, and set your brand apart from the crowd. So, why settle for average when you can team up with the best and watch your brand shine online?",
    whyChooseUs:
      "Enter the digital wonderland with MappOptimist Technologies, your go-to destination for enchanting digital experiences. Renowned in the IT market, MappOptimist specializes in crafting mesmerizing WordPress websites that captivate and inspire. But that's not all – armed with powerful SEO strategies and cutting-edge technological tools, they ensure your website stands out and shines brightly online. For a journey into digital magic and unparalleled online success, MappOptimist is your trusted guide.",
    whyChooseUsSubTitle: "",

    technology: [
      {
        id: 1,
        name: "Google Analytics",
        image: GoogleAnalytics,
      },
      {
        id: 2,
        name: "HubSpot",
        image: HubSpot,
      },
      {
        id: 3,
        name: "Mailchimp",
        image: MailChimp,
      },
      {
        id: 4,
        name: "Hootsuite",
        image: Hootsuite,
      },
      {
        id: 5,
        name: "Salesforce",
        image: SalesForce,
      },
      {
        id: 6,
        name: "Marketo",
        image: Marketo,
      },
      {
        id: 7,
        name: "SEMrush",
        image: semRush,
      },
      {
        id: 8,
        name: "Ahrefs",
        image: ahrefs,
      },
      {
        id: 9,
        name: "LinkedIn Recruiter",
        image: linkedin,
      },
      {
        id: 10,
        name: "Indeed",
        image: indeed,
      },
      {
        id: 11,
        name: "Workday",
        image: WorkDay,
      },
      {
        id: 12,
        name: "Oracle HCM",
        image: oracle,
      },
    ],
  },
  {
    id: 9,
    icon: aiIcon,
    title: "OCR-ICR",
    mainImg: WebDevelopment,
    subImg: AiSubImg,
    subTitle: "Choose The Best AI/ML Development Company",
    shortDescription:
      "OCR/ICR is technology that converts documents into editable text, with ICR also recognizing and interpreting handwritten text using machine learning.",
    description:
      "Artificial Intelligence (AI) embodies the frontier of technological advancement, a realm where machines emulate human cognitive functions to perceive, understand, and respond to the world around them. In this expansive landscape, AI acts as a catalyst, driving innovation and reshaping industries by harnessing the power of technology to solve complex problems. At the heart of AI lies Machine Learning (ML), a dynamic subset that propels machines towards autonomy and proficiency through experiential learning. Unlike traditional programming, ML equips machines with the ability to glean insights from vast datasets, adapt, and improve their performance over time. Through iterative analysis and refinement, ML algorithms unlock new realms of efficiency and intelligence, revolutionizing how we interact with technology.",
    whyChooseUs:
      "Experience matters in the realm of Artificial Intelligence and Machine Learning, and MappOptimist Technologies stands tall as a beacon of expertise with nearly a decade of mastery in these cutting-edge domains. With a profound understanding of the intricacies of AI/ML technologies, Harnessing the power of AI and ML, MappOptimist empowers businesses to unlock new possibilities, solve complex problems, and drive unprecedented growth.",
    whyChooseUsSubTitle:
      "With a wealth of experience in implementing AI/ML solutions, MappOptimist possesses the expertise to navigate the nuances of these technologies, delivering tailored solutions that align with business objectives and drive tangible results. In a world where AI/ML hold the keys to unlocking competitive advantage, MappOptimist emerges as the trusted partner of choice, offering unparalleled technical prowess, strategic guidance, and unwavering commitment to client success",
    technology: [
      {
        id: 1,
        name: "Python",
        image: Python,
      },
      {
        id: 2,
        name: "TensorFlow",
        image: TensorFlow,
      },
      {
        id: 3,
        name: "AWS",
        image: Aws,
      },
      {
        id: 4,
        name: "Microsoft Azure",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      },
      {
        id: 5,
        name: "Google Cloud",
        image: GoogleCloud,
      },
      {
        id: 6,
        name: "IBM Watson",
        image: IBMWatson,
      },
      {
        id: 7,
        name: "Keras",
        image: Keras,
      },
      {
        id: 8,
        name: "OpenAI GPT-4",
        image: OpenAi,
      },
      {
        id: 9,
        name: "Kali Linux",
        image: Kalilinux,
      },
      {
        id: 10,
        name: "Wireshark",
        image: WireShark,
      },
      {
        id: 11,
        name: "Splunk",
        image: Splunk,
      },
      {
        id: 12,
        name: "Metasploit",
        image: Metasploit,
      },
    ],
  },
];
