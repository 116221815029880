import image1 from "../assets/clientReviews/image1.webp";
import image2 from "../assets/clientReviews/image2.webp";
import image3 from "../assets/clientReviews/image3.webp";
import image4 from "../assets/clientReviews/image4.webp";
import image5 from "../assets/clientReviews/image5.webp";

import bobble1 from "../assets/portfolio/bobble/bobble1.webp";
import bobble2 from "../assets/portfolio/bobble/bobble2.webp";
import bobble3 from "../assets/portfolio/bobble/bobble3.webp";
import bobble4 from "../assets/portfolio/bobble/bobble4.webp";
import bobblemain from "../assets/portfolio/bobble/bobblemain.webp";
import Fetch1 from "../assets/portfolio/Fetch/Fetch1.webp";
import Fetch2 from "../assets/portfolio/Fetch/Fetch2.webp";
import Fetch3 from "../assets/portfolio/Fetch/Fetch3.webp";
import Fetch4 from "../assets/portfolio/Fetch/Fetch4.webp";
import Fetchmain from "../assets/portfolio/Fetch/Fetchmain.webp";
import FTL1 from "../assets/portfolio/FTL/FTL1.webp";
import FTL2 from "../assets/portfolio/FTL/FTL2.webp";
import FTL3 from "../assets/portfolio/FTL/FTL3.webp";
import FTL4 from "../assets/portfolio/FTL/FTL4.webp";
import FTLmain from "../assets/portfolio/FTL/FTLmain.webp";
import Happy1 from "../assets/portfolio/happy/Happy1.webp";
import Happy2 from "../assets/portfolio/happy/Happy2.webp";
import Happy3 from "../assets/portfolio/happy/Happy3.webp";
import Happy4 from "../assets/portfolio/happy/Happy4.webp";
import Happymain from "../assets/portfolio/happy/Happymain.webp";
import medi1 from "../assets/portfolio/mediHelp/medi1.webp";
import medi2 from "../assets/portfolio/mediHelp/medi2.webp";
import medi3 from "../assets/portfolio/mediHelp/medi3.webp";
import medi4 from "../assets/portfolio/mediHelp/medi4.webp";
import medimain from "../assets/portfolio/mediHelp/medimain.webp";
import onemain from "../assets/portfolio/one/onemain.webp";
import one1 from "../assets/portfolio/one/one1.webp";
import one2 from "../assets/portfolio/one/one2.webp";
import one3 from "../assets/portfolio/one/one3.webp";
import twomain from "../assets/portfolio/two/twomain.webp";
import two1 from "../assets/portfolio/two/two1.webp";
import two2 from "../assets/portfolio/two/two2.webp";
import two3 from "../assets/portfolio/two/two3.webp";
import two4 from "../assets/portfolio/two/two4.webp";
import fiver1 from "../assets/agencies/fiver1.png";
import ios from "../assets/expertise/ios.webp";
import Android from "../assets/expertise/Android.webp";
import Flutter from "../assets/expertise/Flutter.webp";
import React_Native from "../assets/expertise/React_Native.webp";
import Python from "../assets/expertise/Python.webp";
import HTML from "../assets/expertise/HTML.webp";
import Node from "../assets/expertise/Node.webp";
import JAVA from "../assets/expertise/JAVA.webp";
import Blockchain from "../assets/expertise/Blockchain.webp";
import Amazon from "../assets/expertise/Amazon.webp";
import IoT from "../assets/expertise/IoT.webp";
import Oracle from "../assets/expertise/Oracle.webp";
import SQL from "../assets/expertise/SQL.webp";
import Salesforce from "../assets/expertise/Salesforce.webp";
import Golang from "../assets/expertise/Golang.webp";
import dotnet from "../assets/expertise/dotnet.webp";
import DevOps from "../assets/expertise/DevOps.webp";
import uiux from "../assets/expertise/uiux.webp";
import adhaar from "../assets/OcrIcr/adhaar.webp";
import adhaarJson from "../assets/OcrIcr/adhaarJson.webp";
import document from "../assets/OcrIcr/document.webp";
import documentJson from "../assets/OcrIcr/documentJson.webp";
import passport from "../assets/OcrIcr/passport.webp";
import passportJaon from "../assets/OcrIcr/passportJaon.webp";
import Jabier from "../assets/aboutUs/Jabier.webp";
import Carmel from "../assets/aboutUs/Carmel.webp";
import Angela from "../assets/aboutUs/Angela.webp";
import John from "../assets/aboutUs/John.webp";
import Austin from "../assets/aboutUs/Austin.webp";
import techno1 from "../assets/Services/techno-1.png";
import techno2 from "../assets/Services/techno-2.png";
import techno3 from "../assets/Services/techno-3.png";
import techno4 from "../assets/Services/techno-4.png";
import techno5 from "../assets/Services/techno-5.png";
import techno6 from "../assets/Services/techno-6.png";
import techno7 from "../assets/Services/techno-7.png";
import techno8 from "../assets/Services/techno-8.png";
import techno9 from "../assets/Services/techno-9.png";
import techno10 from "../assets/Services/techno-10.png";
import techno11 from "../assets/Services/techno-11.png";
import techno12 from "../assets/Services/techno012.png";
import upwork1 from "../assets/agencies/upwork1.png";
import freelancer1 from "../assets/agencies/freelancer1.png";
import indeed1 from "../assets/agencies/indeed1.png";
import behance1 from "../assets/agencies/behance1.png";
import flexjob1 from "../assets/agencies/flexjob1.png";
import pangian1 from "../assets/agencies/pengian1.png";
import dribble1 from "../assets/agencies/dribble1.png";
import LatestCreation from "../assets/Services/latestCreation.png";
import LatestCreation2 from "../assets/Services/latestCreation1.png";
import LatestCreation3 from "../assets/Services/latestCreation2.jpeg";
import LatestCreation4 from "../assets/Services/latestCreation3.png";
import LatestCreation5 from "../assets/Services/latestCreation4.png";
import blog_1 from "../assets/blogs/blog_1.jpg";
import blog_2 from "../assets/blogs/blog_2.webp";
import blog_3 from "../assets/blogs/blog_3.png";

export const about = [
  {
    id: 1,
    number: "10 ",
    symbol: "+",
    title: "Years of Experience",
    description:
      "We have experienced professionals to help you build great projects.",
  },
  {
    id: 2,
    number: "150 ",
    symbol: "+",
    title: "Projects Completed",
    description: "In our team can help you build your project.",
  },
  {
    id: 3,
    number: "200",
    symbol: "%",
    title: "Happy Clients",
    description: "Our clients satisfaction is our first priority.",
  },
  {
    id: 4,
    number: "100",
    symbol: "%",
    title: "On-time Delivery",
    description: "We have a good record of delivering projects before time.",
  },
];

export const portfolioImages = [
  {
    id: "Bobble",
    css: "hidden middleXl:block mx-auto midms:w-[95%] xsm:w-[89%] sm:w-[60%] sm:mt-32 midFM:mt-10 xmd:-mt-[77%] midxmd:w-[65%] mt-14 middleXmd:-mt-32 middleXmd:mr-[5%] ",
    image: bobble2,
    title: "Bobble",
    mainImage: bobble4,
    feature: bobblemain,
    onhover:
      "Bobble is the most usable keyboard and has all keyboard features in one. There's never a boring chat with Bobble.",
    feature1: "Bigmoji",
    feature1Desc:
      "The Bigmoji feature enhances the typical emoji experience by enlarging the size of the selected emoji, making it more visually prominent and expressive.",

    feature2: "Suggestion Drawer",
    feature2Desc:
      "Real-time sticker sharing adds dynamic expression to conversations, enhancing communication with instant and adaptable visuals.",

    feature3: "Fonts",
    feature3Desc:
      "Fun and cool font support enhances conversations, adding an exciting feature that brings a unique and enjoyable way to communicate.",

    feature4: "Themes",
    feature4Desc:
      "Customize themes for a personalized touch, switch from pre-set options to achieve greater personalization, and even add your own image as the keyboard theme.",
    feature5: "Jokes ",
    feature5Desc:
      "Spread joy with a single tap. Enjoy categorized jokes for filtering, making it easy to share happiness in your preferred language.",
    feature6: "Custom Stickers",
    feature6Desc:
      "This feature enables users to create personalized face stickers with various expressions and share them as custom stickers.",
    feature7: "ATW",
    feature7Desc:
      ' Users can conveniently add their preferred sticker packs directly to the WhatsApp application using the "Add to WhatsApp" feature.',
    feature8: "Stories",
    feature8Desc:
      "Users can create and update custom stories with their partners, adding personalized text for an enhanced experience.",
    feature9: "GIF's",
    feature9Desc:
      "Users have the ability to share custom GIFs containing meme content and can also customize the head support in the GIFs.",
    feature10: "AI",
    feature10Desc:
      "AI models are employed to enhance typing experiences by providing precise suggestions, predictions, and automatic corrections.",

    leftImage: bobble3,
    rightImage: bobble1,
    rightImage1: "ATW  Feature",
    rightImage1Desc:
      "Deep linking was implemented, utilizing standard iOS and Android practices and clever techniques to enable seamless redirection between our app and WhatsApp.",
    rightImage2: "AI Model Integration",
    rightImage2Desc:
      "AI tflite models were integrated with memory optimization and concurrency. Repetitive code was optimized using industry-standard design patterns.",
    rightImage3: "CST Feature",
    rightImage3Desc:
      "The content suggestion drawer utilized various APIs, with responses parsed using protocols, serialization, etc. The obtained data was seamlessly integrated into the designed UI/UX.",
  },
  {
    id: "FTL",
    image: FTL2,
    css: "hidden middleXl:block mx-auto w-[53%] midms:w-[45%] sm:w-[70%] sm:mt-2 midFM:w-[50%] midFM:-mr-6 midFM:mt-10 xmd:-mt-[77%] midxmd:w-[30%] mt-14 middleXmd:-mt-32 middleXmd:mr-[24%] ",
    title: "FTL: Fantasy trading league",
    mainImage: FTL4,
    feature: FTLmain,
    onhover:
      "FTL provides real-time data for crypto & stocks, offering an authentic trading experience with simulated funds.",
    feature1: "Real-time data",
    feature1Desc:
      "Real-time market data: trading prices, volumes, and indicators that inform investors, enabling timely decision-making.",

    feature2: "TOROai",
    feature2Desc:
      "TOROai: a user-friendly chatbot offering real-time financial market data, analysis, and insights to keep users informed about finance.",

    feature3: "Trading experience",
    feature3Desc:
      "LLive trading immerses individuals in real-time buying/selling of financial instruments. Learn market intricacies, observe price fluctuations, and analyze trends hands-on.",

    feature4: "Gameplay",
    feature4Desc:
      "Gameplay offers an immersive experience, to simulate real-world trading scenarios, strategies, and gain financial knowledge while enjoying the thrill of virtual trading.",
    feature5: "Charts for market analysis",
    feature5Desc:
      "Charts provide visual representation of market data, to analyze price patterns, aiding in informed decision-making for effective market analysis.",
    feature6: "Reward system",
    feature6Desc:
      "Reward system incentivizes users with bonuses or  exclusive perks or active participation, enhancing user motivation, and overall trading experience.",
    feature7: "",
    feature7Desc: "",
    feature8: "",
    feature8Desc: "",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",

    leftImage: FTL3,
    rightImage: FTL1,
    rightImage1: "Push notification",
    rightImage1Desc:
      "Push notifications using Firebase enable real-time communication with app users. With personalized messages, updates, and alerts, it enhances engagement, user retention, and delivers timely information, boosting the overall app experience.",
    rightImage2: "AI Model Integration",
    rightImage2Desc:
      "The AI chat support offers intelligent assistance tailored to the financial market. With advanced algorithms, it provides users with personalized insights, enhancing decision-making and enabling seamless engagement with the financial market.",
    rightImage3: "CST Feature",
    rightImage3Desc:
      "The content suggestion drawer utilized various APIs, with responses parsed using protocols, serialization, etc. The obtained data was seamlessly integrated into the designed UI/UX.",
  },
  {
    id: "GoFetch",
    css: "hidden middleXl:block mx-auto scale-[2] mt-36 midms:w-[95%] xsm:w-[89%] sm:w-[100%] sm:scale-[2.5] sm:-mr-24 sm:mt-44 midFM:mt-10 midFM:scale-[2.2] xmd:-mt-[70%] midxmd:w-[75%] middleXmd:-mt-32 middleXmd:scale-[2] middleXmd:mr-[4%] ",
    image: Fetch2,
    title: "GoFetch",
    mainImage: Fetch4,
    feature: Fetchmain,
    onhover:
      "Discover a world of online shopping at your fingertips. Browse, compare, and buy products with ease through our intuitive GoFetch app.",
    feature1: "Languages",
    feature1Desc:
      "Shop easily with GoFetch. Browse, compare, and buy products conveniently through the online shopping experience.",

    feature2: "Home",
    feature2Desc:
      "Users can discover endless possibilities to shop the latest trends, find exclusive deals, and experience seamless shopping on our captivating Go Fetch App.",

    feature3: "Catalogue",
    feature3Desc:
      "Find a diverse range of brands to explore, filter, and fulfil your expectations. Our catalogue includes items for all age groups - kids, teens, and adults.",

    feature4: "Payment",
    feature4Desc:
      "Enjoy peace of mind with our trusted and reliable payment gateway, offering seamless and secure transactions for hassle-free experiences",
    feature5: "Cart",
    feature5Desc:
      "Users can keep track of their selections in one place as well as easily review, modify, and finalize the shopping cart for a smooth checkout experience",
    feature6: "Activities",
    feature6Desc:
      "Users can access their complete order history at a glance. Track deliveries, view past purchases, and manage returns effortlessly on this page",
    feature7: "Authentication",
    feature7Desc:
      " Users can effortlessly secure their accounts, safeguard their personal information, and enjoy a secure login experience using our trusted authentication page.",
    feature8: "Sidebar",
    feature8Desc:
      "Users can navigate with ease. Access key features, categories, settings, and personalized options conveniently from the sidebar menu on our app.",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",
    leftImage: Fetch3,
    rightImage: Fetch1,
    rightImage1: "Payment",
    rightImage1Desc:
      "To establish customer confidence, our app incorporates various payment gateways like Apple Pay, Google Pay, Paytm, etc., ensuring seamless in-app transactions.",
    rightImage2: "Cart",
    rightImage2Desc:
      "We have designed UI/UX, implemented add/remove functionality, calculated total cost, enable quantity modification, and integrated with checkout for our app’s cart page.",
    rightImage3: "Filtering",
    rightImage3Desc:
      "Our app utilizes highly optimized algorithms like Tries, Hashing, and more to deliver a swift and seamless filtering experience.",
  },
  {
    id: "Website1",
    image: onemain,
    css: "hidden middleXl:block mx-auto mt-[45%] midms:w-[95%] xsm:w-[89%] sm:w-[600%] sm:mt-[60%] midFM:mt-32 xmd:-mt-[77%] midxmd:w-[75%] middleXmd:-mt-[1%] rotate-[4deg] middleXmd:mr-[4%] ",
    title: "Website",
    mainImage: one3,
    feature: onemain,
    onhover:
      "Our mission is to deliver top-notch Tile & Stone Installation Solutions worldwide, ensuring high-quality projects completed on time with unwavering trust.",
    feature1: "Responsiveness",
    feature1Desc:
      "This website dynamically adjusts its layout and content to provide an optimal viewing experience across various devices and screen sizes.",

    feature2: "Animations",
    feature2Desc:
      "This website incorporates captivating animations and transitions to enhance user engagement and create a visually appealing experience.",

    feature3: "SEO Friendly",
    feature3Desc:
      "This website is optimized for search engines, allowing it to rank higher in search results and attract organic traffic.",

    feature4: "Swift",
    feature4Desc:
      "This website loads quickly, providing a seamless and efficient browsing experience for users with minimal waiting times.",
    feature5: "Effortless Navigation",
    feature5Desc:
      "This website ensures a smooth user experience by offering intuitive menus, clear organization, and seamless transitions.",
    feature6: "Top Quality Content",
    feature6Desc:
      "This website offers well-crafted, informative, and engaging material that meets users' needs and provides valuable information.",
    feature7: "Secure",
    feature7Desc:
      "This website implements robust measures to protect user data, ensuring privacy and providing a safe browsing environment for visitors",
    feature8: "Analytics",
    feature8Desc:
      "This web.site integrates tools to collect data, analyze user behavior, and gain insights for informed decision-making.",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",
    leftImage: one2,
    rightImage: one1,
    rightImage1: "Responsiveness",
    rightImage1Desc:
      "To build website responsiveness we focused on flexible layouts, fluid grids, media queries, and optimize images. Test on various devices and adjust as needed for optimal user experience.",
    rightImage2: "Fast Loading time",
    rightImage2Desc:
      "We have optimized images and code, minimize HTTP requests, leverage caching, use a content delivery network (CDN), and prioritize critical resources for efficient loading.",
    rightImage3: "Real Time Data",
    rightImage3Desc:
      "To build website's animations, define animation goals, we planned key interactions, use CSS or JavaScript frameworks, optimize performance, and test across browsers.",
  },
  {
    id: "Website2",
    css: "hidden middleXl:block mx-auto w-full scale-[2.2] ml-6 mt-[65%] midms:w-[95%] xsm:w-[89%] xsm:mt-52 sm:w-[60%] sm:scale-[4]  sm:ml-24 sm:mt-[100%] midFM:mt-[62%] xmd:-mt-[98%] xmd:scale-[2.4] xmd:ml-44 midxmd:w-[75%] middleXmd:-mt-[10%] mr-[10%] -translate-x-10 ",
    image: two2,
    title: "Website",
    mainImage: two1,
    feature: twomain,
    onhover:
      "This website offers room sales services, facilitating seamless transactions and providing a user-friendly experience for buyers and sellers.",
    feature1: "Responsiveness",
    feature1Desc:
      "This website dynamically adjusts its layout and content to provide an optimal viewing experience across various devices and screen sizes.",

    feature2: "Animations",
    feature2Desc:
      "This website incorporates captivating animations and transitions to enhance user engagement and create a visually appealing experience.",

    feature3: "SEO Friendly",
    feature3Desc:
      "This website is optimized for search engines, allowing it to rank higher in search results and attract organic traffic.",

    feature4: "Swift",
    feature4Desc:
      "This website loads quickly, providing a seamless and efficient browsing experience for users with minimal waiting times.",
    feature5: "Effortless Navigation",
    feature5Desc:
      "This website ensures a smooth user experience by offering intuitive menus, clear organization, and seamless transitions.",
    feature6: "Top Quality Content",
    feature6Desc:
      "This website offers well-crafted, informative, and engaging material that meets users' needs and provides valuable information.",
    feature7: "Secure",
    feature7Desc:
      " This website implements robust measures to protect user data, ensuring privacy and providing a safe browsing environment for visitors.",
    feature8: "Analytics",
    feature8Desc:
      "This website integrates tools to collect data, analyze user behavior, and gain insights for informed decision-making.",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",
    leftImage: two3,
    rightImage: two4,
    rightImage1: "SEO Friendly",
    rightImage1Desc:
      "To build an SEO-friendly website, we focused on keyword research, optimized content, meta tags, proper URL structure, mobile-friendliness, and link building strategies.",
    rightImage2: "Secure",
    rightImage2Desc:
      "To build a secure website,we have implemented SSL encryption, secure hosting, strong passwords, regular updates, and vulnerability scanning to protect user data and privacy.",
    rightImage3: "Analytics and Tracking",
    rightImage3Desc:
      "To build analytics and tracking capabilities, we have integrated tracking codes, set up conversion goals, configure event tracking, and analyze data for insights and optimization.",
  },
  {
    id: "MediHelp",
    css: "hidden sm:block mx-auto w-[100%] mt-40 scale-[1.2] midms:w-[87%] sm:w-[100%] sm:mt-52 sm:ml-4 sm:scale-[1.5] midFM:w-[90%] midFM:-mr-12 midFM:mt-40 xmd:-mt-[77%] midxmd:w-[65%] middleXmd:-mt-32 middleXmd:scale-[1.7]  middleXmd:ml-[14%] ",
    image: medi2,
    title: "MediHelp",
    mainImage: medi4,
    feature: medimain,
    onhover:
      "MediHelp app: Connect with doctors, book appointments, manage profiles, access health tips, and emergency contacts.",
    feature1: "DualAuth",
    feature1Desc:
      "This app facilitates patient and doctor login with separate credentials, providing individual profiles and dashboards for each user.",

    feature2: "Appointments",
    feature2Desc:
      "Patients can view doctor availability and book appointments online, selecting preferred time and date ranges to generate a final appointment receipt.",

    feature3: "Add Medicine",
    feature3Desc:
      " Add medicine into a patient's treatment plan, enhancing their therapeutic options and addressing specific health needs prescribed by the doctor.",

    feature4: "Prescriptions",
    feature4Desc:
      "Patients can access and manage their prescriptions in one location, with the ability to view detailed medication schedules for intake timings.",
    feature5: "Upcoming",
    feature5Desc:
      "Scheduled patient consultations and medical sessions, ensuring timely healthcare delivery and effective patient management.",
    feature6: "Test Reports",
    feature6Desc:
      "Patients can access and download their test reports, while also having the option to add comments for clarification, which will be addressed by a doctor.",
    feature7: "",
    feature7Desc: "",
    feature8: "",
    feature8Desc: "",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",
    leftImage: medi3,
    rightImage: medi1,
    rightImage1: "Appointments Feature",
    rightImage1Desc:
      "The content suggestion drawer utilized various APIs, with responses parsed using intents, serialization, etc. The obtained data was seamlessly integrated into the designed UI/UX.",
    rightImage2: "Test Reports",
    rightImage2Desc:
      "The app incorporates Google Text-to-Speech, allowing doctors to conveniently generate reports by narrating them through voice, and automatically transcribing them.",
    rightImage3: "Prescription",
    rightImage3Desc:
      "The prescription is personalized with dropdown options and allows selecting dates from a calendar, tailoring medication instructions to individual needs for enhanced convenience and accuracy.",
  },
  {
    id: "HappyRide",
    image: Happy2,
    css: "hidden middleXl:block mx-auto w-[53%] midms:w-[45%] sm:w-[70%] sm:mt-2 midFM:w-[50%] midFM:-mr-6 midFM:mt-10 xmd:-mt-[77%] midxmd:w-[60%] mt-14 middleXmd:-mt-28 middleXmd:mr-[15%] ",
    title: "Happy Ride",
    mainImage: Happy4,
    feature: Happymain,
    onhover:
      " Effortlessly book taxis, track your ride, and pay securely with our user-friendly and reliable taxi booking app.",
    feature1: "Map",
    feature1Desc:
      " This app offers a real-time location rendering map for users to track their own and assigned rider's location, distance, and more.",

    feature2: "Rent",
    feature2Desc:
      "In addition to offering taxi rentals for drop-off purposes, this app allows users to rent taxis for their personal use, enabling multi-day rides.",

    feature3: "Live Data",
    feature3Desc:
      "Users can view nearby drivers, access their live locations, and see the type of vehicles they are using in real-time.",

    feature4: "Personalization",
    feature4Desc:
      "User can Rent their  favorite car models and customize them with color, seating arrangement, music system, and more through our app.",
    feature5: "GPS",
    feature5Desc:
      "Users can effortlessly navigate with GPS, find optimal routes, and reach thier destination efficiently using our cab booking app's built-in navigation.",
    feature6: "Split Fare",
    feature6Desc:
      "Users can easily split fares with friends and share rides by leveraging our cab booking app's convenient split fare option.",
    feature7: "",
    feature7Desc: "",
    feature8: "",
    feature8Desc: "",
    feature9: "",
    feature9Desc: "",
    feature10: "",
    feature10Desc: "",
    leftImage: Happy3,
    rightImage: Happy1,
    rightImage1: "Payment",
    rightImage1Desc:
      "To establish customer confidence, our app incorporates various payment gateways like Apple Pay, Google Pay, Paytm, etc., ensuring seamless in-app transactions.",
    rightImage2: "GPS",
    rightImage2Desc:
      "Our cab booking  app seamlessly incorporates Google Maps, enabling destination search, route selection, and turn-by-turn navigation instructions.",
    rightImage3: "Real Time Data",
    rightImage3Desc:
      "In our app, real-time updates are facilitated through a WebSocket Server, allowing live updates of user interface components with received data.",
  },
];

export const agencies = [
  {
    id: 1,
    image: behance1,
    name: "Behance logo",
  },
  {
    id: 2,
    image: dribble1,
    name: "Dribble logo",
  },
  {
    id: 3,
    image: fiver1,
    name: "Fiverr logo",
  },
  {
    id: 4,
    image: upwork1,
    name: "Upwork logo",
  },
  {
    id: 5,
    image: freelancer1,
    name: "Freelancer logo",
  },
  {
    id: 6,
    image: pangian1,
    name: "Pangian logo",
  },
  {
    id: 7,
    image: indeed1,
    name: "Indeed logo",
  },
  {
    id: 8,
    image: flexjob1,
    name: "Flexjobs logo",
  },
];

export const reviews = [
  {
    id: 1,
    image: image1,
    name: "Olivia Diniz",
    date: "03/04/2020",
    description:
      "The IT services and solutions provided by this company are top-notch. Their expertise, reliability and efficiency have greatly enhanced our business operations. Enthusiastically endorsed!",
  },
  {
    id: 2,
    image: image2,
    name: "Renato Weiser",
    date: "23/09/2020",
    description:
      "Reliable and efficient IT services provider; their solutions transformed our operations. The team's expertise, responsiveness and commitment to excellence made them an invaluable partner.",
  },
  {
    id: 3,
    image: image3,
    name: "Sofia Coasta",
    date: "20/07/2022",
    description:
      "Exceptional IT services! The company's expertise and efficient solutions have transformed our business. Their prompt support and innovative approach make them a reliable partner. Highly recommended!",
  },
  {
    id: 4,
    image: image4,
    name: "Wilton Hou",
    date: "12/06/2019",
    description:
      "Highly skilled iOS development team; they crafted a flawless app for our business. Their expertise, professionalism and timely delivery exceeded our expectations. Highly recommended!",
  },
  {
    id: 5,
    image: image5,
    name: "Olive Kogo",
    date: "01/01/2022",
    description:
      "Exceptional Android development services; they built an impressive app that perfectly catered to our needs. Their team's expertise, attention to detail and prompt support made the experience seamless.",
  },
];

export const expertiseItem = [
  {
    id: 1,
    image: ios,
    name: "iOS",
    desc: "Our team of iOS experts to deliver cutting-edge, user-friendly apps using Swift, Swift UI, and Objective-C. With a focus on industry best practices, we guarantee seamless functionality, optimized performance, and intuitive interfaces. Rely on us for comprehensive end-to-end iOS development services.",
    hoverDesc:
      "MacOs, tvOS , WatchOs, ARKit , MapKit , UIKit, SpriteKit, Socket io, Charts, Cocoapods, Swift UI, Swift , Objective C , Core Data , Realm, APNS , FCM, Authentication and Authorization, HealthKit, XCTests, Core Animation etc.",
  },
  {
    id: 2,
    image: Android,
    name: "Android",
    desc: "Our company for comprehensive services and solutions. Our team of skilled professionals in crafting innovative and user-friendly Android applications, customized to your needs. Trust us for top-notch end-to-end Android app development, from concept to post-launch support, ensuring exceptional quality.",
    hoverDesc:
      " Java, Kotlin, Android SDK, JSON , SQLite, Retrofit, Firebase , Gradle, Material Design, Dagger, RxJava, Glide, Room, ButterKnife OkHttp, Gson,RxJava, AdMob ,Recycler View, Goggle Play Services, Picasso etc.",
  },
  {
    id: 3,
    image: Flutter,
    name: "Flutter",
    desc: "Our company offers comprehensive services and solutions. With a team of skilled experts, we create cross-platform applications with Flutter's framework, enabling seamless functionality across iOS and Android. Trust us for top-quality, end-to-end Flutter development tailored to your specific requirements",
    hoverDesc:
      "Dart , Flutter Framewrok , Flutterflow , Cupertino, State Management Tools (Provider, Redux,etc),  flutter_test, Mockito, Widget libraries, Firebase Cloud Messaging, In-App Purchases,Bloc, RxDart, Firestore, etc",
  },
  {
    id: 4,
    image: React_Native,
    name: "React Native",
    desc: "Our company excels in React Native development, providing tailored solutions for iOS and Android apps. Our expert team crafts high-quality mobile experiences, delivering customized solutions, seamless integrations, and efficient maintenance. Cost-effective and timely, they prioritize client satisfaction.",
    hoverDesc:
      "React Native , Java Script , JSX, Node.js, Redux , Expo, React  Navigation, TypeScript, GraphQL, Axios, Async Storage, MobX , Fastlane, etc.",
  },
  {
    id: 5,
    image: Python,
    name: "Python",
    desc: "Our company provides a comprehensive array of services. Our skilled team excels at delivering Python applications that cater to your specific requirements. Whether it's web development, data analysis, or machine learning, rely on us for top-quality Python solutions that propel your business to new heights.",
    hoverDesc:
      "Python, Django, Flask, NumPy, Pandas, Matplotlib, SciPy, TensorFlow, PyTorch, Scikit-learn, Keras, SQLAlchemy, Django REST framework, Celery, BeautifulSoup, Requests, Selenium, Docker, Git, Jupyter Notebook, etc",
  },
  {
    id: 6,
    image: HTML,
    name: "HTML + CSS",
    desc: "With a focus on frontend development, our company delivers comprehensive solutions in HTML, CSS, and JavaScript. Our skilled experts specialise in crafting visually stunning and interactive web applications. We customize our services to align with your requirements, guaranteeing exceptional outcomes.",
    hoverDesc:
      "HTML5, CSS3, JavaScript, jQuery, Bootstrap, React.js, AngularJS, Vue.js, Sass, LESS, Babel, Webpack, Grunt, Gulp, ESLint, Prettier, PostCSS, AJAX, JSON, Responsive design, etc",
  },
  {
    id: 7,
    image: Node,
    name: "Node JS",
    desc: "Our specialized team offering comprehensive services and solutions in Node.js and PHP. With our skilled team we create robust and scalable applications for you. Whether it's building APIs, handling databases, or implementing server-side logic, trust us for reliable backend solutions to your specific needs.",
    hoverDesc:
      "Node.js, Express.js, MongoDB, MySQL, PostgreSQL, Redis, GraphQL, RESTful APIs, JWT (JSON Web Tokens), Sequelize, Mongoose, Socket.IO, Passport.js, PHP, Laravel, Symfony, CodeIgniter, MySQLi, Composer, PHPUnit, etc",
  },
  {
    id: 8,
    image: JAVA,
    name: "JAVA",
    desc: "Our company specializes in Java development, offering comprehensive services and solutions. With our skilled team of experts, we create robust and scalable applications. From enterprise software development to web applications and mobile apps, trust us for reliable Java solutions tailored to your needs.",
    hoverDesc:
      "Java, Spring Framework, Hibernate, Maven, Gradle, JUnit, Apache Tomcat, RESTful APIs, JDBC, Git, IntelliJ IDEA, Eclipse, JavaFX, Log4j, JSON, XML, MySQL, PostgreSQL, Oracle, Apache Kafka, etc",
  },
  {
    id: 9,
    image: Blockchain,
    name: "Blockchain",
    desc: "Our specialized team in Blockchain development, offering comprehensive services and solutions. We create secure and decentralized applications. From smart contract development to cryptocurrency solutions and blockchain consulting, trust us for reliable and innovative solutions tailored to your needs.",
    hoverDesc:
      "Smart Contracts, Ethereum, Solidity, Hyperledger Fabric, Web3.js, Truffle, Ganache, Remix, IPFS, Consensus Algorithms, Cryptography, DApps, Tokenization, Decentralized Finance (DeFi), Wallet Development, Chainlink, Quorum, Corda, Bitcoin, Ripple, etc",
  },
  {
    id: 10,
    image: Amazon,
    name: "Amazon Web",
    desc: "We specialize in AWS development, delivering comprehensive and tailored solutions. Our expert team creates scalable, secure, and reliable cloud-based applications, covering infrastructure setup, serverless computing, and machine learning. Trust us for top-quality AWS solutions that meet your unique needs.",
    hoverDesc:
      "EC2, S3, Lambda, RDS, DynamoDB, CloudFormation, Elastic Beanstalk, API Gateway, CloudFront, CloudWatch, IAM, SNS, SQS, SES, Route 53, VPC, Aurora, AWS CLI, Elastic Load Balancer, CloudTrail, etc",
  },
  {
    id: 11,
    image: IoT,
    name: "IOT",
    desc: "Our team specialized in IoT development, offering comprehensive services and solutions. We create innovative and connected solutions for the Internet of Things. From device integration to data analytics and cloud connectivity, trust us for reliable and scalable IoT solutions tailored to your specific needs.",
    hoverDesc:
      "Internet of Things (IoT), MQTT, CoAP, Zigbee, Z-Wave, Bluetooth, LoRaWAN, WiFi, RFID, Edge Computing, Sensor Networks, Cloud Platforms, Arduino, Raspberry Pi, Node-RED, AWS IoT, Microsoft Azure IoT, Google Cloud IoT, IBM Watson IoT, ThingSpeak, etc",
  },
  {
    id: 12,
    image: Oracle,
    name: "Oracle",
    desc: "Our company specialized in Oracle development, offering comprehensive services and solutions. We create robust and scalable applications on Oracle platforms. From database design and management to Oracle E-Business Suite customization, trust us for reliable Oracle solutions tailored to your specific needs.",
    hoverDesc:
      "Oracle Database, Oracle SQL, PL/SQL, Oracle Application Express (APEX), Oracle Forms, Oracle Reports, Oracle Fusion Middleware, Oracle WebLogic Server, Oracle SOA Suite, Oracle Data Integrator (ODI), Oracle BI (Business Intelligence), Oracle Database Appliance, Oracle RAC, Oracle Advanced Security, etc",
  },
  {
    id: 13,
    image: SQL,
    name: "SQL Service",
    desc: "Specializing in SQL services, we offer tailored solutions for your database needs. Our skilled team provides efficient database design, optimization, and administration services. From data modelling to query performance tuning, trust us for reliable and scalable SQL services that drive your business forward.",
    hoverDesc:
      "SQL, MySQL, Microsoft SQL Server, Oracle Database, PostgreSQL, SQLite, MariaDB, MongoDB, CouchDB, Redis, Elasticsearch, Apache Cassandra, IBM DB2, Amazon RDS, Google Cloud SQL, Azure SQL Database, Firebase, Hadoop, Apache Hive, Apache Spark, etc",
  },
  {
    id: 14,
    image: Salesforce,
    name: "Salesforce",
    desc: "Our team specializes in Salesforce development. We create customized Salesforce solutions tailored to your business needs. From CRM implementation and customization to integration and support, and efficient Salesforce development that helps drive your sales and customer relationship management processes.",
    hoverDesc:
      "Salesforce, Apex, Visualforce, Lightning Components, SOQL, SOSL, Salesforce Lightning, Salesforce DX, Salesforce Connect, Salesforce Mobile, Salesforce Einstein, Salesforce Communities, Salesforce AppExchange, Salesforce Integration, Salesforce REST API, etc",
  },
  {
    id: 15,
    image: Golang,
    name: "GoLang",
    desc: "We specialize in Go development, offering comprehensive services and solutions. Our expert team creates efficient and scalable applications using Go. Trust us for reliable and high-performance Go solutions tailored to your specific needs, including web development, microservices, and system programming.",
    hoverDesc:
      "Go (Golang), Gorilla/Mux, Gin, Revel, Echo, GORM, PostgreSQL, MySQL, MongoDB, Redis, gRPC, RESTful APIs, JWT (JSON Web Tokens), Docker, Kubernetes, Prometheus, GraphQL, Kafka, RabbitMQ, AWS SDK,etc",
  },
  {
    id: 16,
    image: dotnet,
    name: "Dot Net",
    desc: "Experience the power of Dot Net technology with our innovative IT solutions. From application development to integration and support, we deliver reliable, secure and scalable solutions tailored to your business needs. Partner with us for accelerated growth and digital transformation.",
    hoverDesc:
      "ASP.NET, C#, VB.NET, .NET Framework, .NET Core, Entity Framework, ASP.NET MVC, WPF (Windows Presentation Foundation), WinForms (Windows Forms), Xamarin, ASP.NET Web API etc.",
  },
  {
    id: 17,
    image: DevOps,
    name: "DevOps",
    desc: "Accelerate your software delivery with our DevOps services. Streamline workflows, automate deployments, and enhance collaboration. Leverage cutting-edge tools like Docker, Kubernetes, Jenkins, and Ansible for seamless integration and continuous improvement. Drive innovation and achieve faster time to market.",
    hoverDesc:
      "Docker, Kubernetes, Jenkins, Ansible, Git, Puppet, Chef, Terraform, Prometheus, ELK Stack, Nagios, Grafana, AWS, Azure DevOps, GitLab, Jira, Slack, Selenium, SonarQube, Docker Compose",
  },
  {
    id: 18,
    image: uiux,
    name: "UI/UX",
    desc: "Enhance user engagement with our UI/UX services. Our skilled team crafts intuitive interfaces, seamless interactions, and visually stunning designs to create memorable digital experiences. Let's create a user-centric journey that captivates your audience and drives business growth.",
    hoverDesc:
      "Adobe XD, Sketch, InVision, Figma, Zeplin, Axure RP, Marvel, Balsamiq, Principle, Proto.io, Sketchbook, UXPin, Flinto, Justinmind, Protopie, Adobe Photoshop, Adobe Illustrator, Adobe After Effects, HTML/CSS, JavaScript, React, Vue.js, Angular, Sass, Bootstrap, etc.",
  },
];

export const Ai = [
  {
    id: 1,
    image: adhaar,
    json: adhaarJson,
  },
  {
    id: 2,
    image: passport,
    json: passportJaon,
  },
  {
    id: 3,
    image: document,
    json: documentJson,
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Jabier Silveira",
    testimonial:
      "I have had an excellent experience thus far working with MappOptimist. The managers and teammates have been incredibly cooperative and friendly. The company consistently delivers stable builds that meet client requirements. Regular calls and communication contribute significantly to building confidence in their services.",
    image: Jabier,
    date: "12",
    monthYear: "Mar. 2023",
    rating: 4,
  },
  {
    id: 2,
    name: "Carmel Gomez",
    testimonial:
      "Working with MappOptimist Technologies has been exceptional. They transform business ideas into reality with their expertise. Thanks to them, I now have a fully functional application. Special gratitude to founder Mr. Yashvant Sikarvar for his direct involvement throughout the process.",
    image: Carmel,
    date: "24",
    monthYear: "Sept. 2022",
    rating: 5,
  },
  {
    id: 3,
    name: "John Deckard",
    testimonial:
      "Working with MappOptimist Technologies has been a game-changer for our business. Their IT solutions are top-notch, delivering impressive results that have significantly improved our operations. We couldn't be happier with their expertise and professionalism.",
    image: John,
    date: "04",
    monthYear: "Feb. 2021",
    rating: 4,
  },
  {
    id: 4,
    name: "Austin Baselga",
    testimonial:
      "Choosing MappOptimist Technologies as our IT solution provider was one of the best decisions we made. They seamlessly integrated our systems, streamlined our processes, and provided excellent ongoing support. Their team's knowledge and dedication have been invaluable to our success.",
    image: Austin,
    date: "04",
    monthYear: "Jan. 2022",
    rating: 5,
  },
  {
    id: 5,
    name: "Angela Badgi",
    testimonial:
      "I recommend MappOptimist Technologies. Their IT solutions have revolutionized our company's efficiency and productivity. The team's innovative approach, coupled with their prompt and reliable support, has exceeded our expectations. We are grateful to have them as our trusted IT partner.",
    image: Angela,
    date: "26",
    monthYear: "Nov. 2021",
    rating: 5,
  },
];

export const technology = [
  { id: 1, image: techno1, name: "jQuery" },
  { id: 2, image: techno2, name: "less" },
  { id: 3, image: techno3, name: "wordPress" },
  { id: 4, image: techno4, name: "syncFusion" },
  { id: 5, image: techno5, name: "html/css" },
  { id: 6, image: techno6, name: "react-native" },
  { id: 7, image: techno7, name: "node" },
  { id: 8, image: techno8, name: "xamarin" },
  { id: 9, image: techno9, name: "sass" },
  { id: 10, image: techno10, name: "bootstrap" },
  { id: 11, image: techno11, name: "flutter" },
  { id: 11, image: techno12, name: "Java" },
];

export const LatestCreationList = [
  { id: 1, img: LatestCreation },
  { id: 2, img: LatestCreation2 },
  { id: 3, img: LatestCreation3 },
  { id: 4, img: LatestCreation4 },
  { id: 5, img: LatestCreation5 },
  {
    id: 6,
    img: "https://acubeapps.com/wp-content/uploads/2023/10/screencapture-goshopmatic-2023-10-17-10_46_41.png",
  },
];

export const blogsData = [
  {
    id: 1,
    imageUrl: blog_1,
    title: "Semiconductor Chip : The Backbone of Modern Technology",
    description: (
      <>
        <p>
          Once dominated by a few regions, the semiconductor manufacturing
          industry is now expanding globally as countries recognize its critical
          importance. If we watch recent geo-political events, including the
          COVID-19 pandemic and geopolitical tensions, have highlighted the
          susceptibility of global supply chain operations. In response, India
          has adopted significant steps, with the TATA group partnering with
          Taiwan&rsquo;s Powerchip Semiconductor Manufacturing Corporation
          (PSMC) to establish a 300mm wafer fabrication plant in Gujarat.
          Additionally, the Indian government has approved two assembly and test
          plants in Gujarat and Assam, underscoring the nation's commitment to
          becoming a key player in the semiconductor field.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>What is a Semiconductor Chip and How is it Made?</strong>
        </p>
        <p>
          A semiconductor is a material with electrical conductivity between
          that of a conductor and an insulator. Pure semiconductors are poor
          conductors of electricity, but their properties can be altered by
          introducing small amounts of other elements, known as dopants. This
          process, called doping, allows specific regions of the semiconductor
          to conduct electricity, enabling the creation of complex circuits
          within the material.
        </p>
        <p>The manufacturing process involves several steps:</p>
        <p>&nbsp;</p>
        <p>
          <strong>Purification and Crystal Growth:</strong> The semiconductor
          material, usually silicon, is purified and melted to form a large
          crystal.
        </p>
        <p>
          <strong>Wafer Production:</strong> The crystal is sliced into thin
          wafers, which are then polished to create a smooth surface.
        </p>
        <p>
          <strong>Photolithography:</strong> A light-sensitive chemical coating
          is applied to the wafer. Patterns are created on the wafer using
          masks, which act like stencils. Ultraviolet light shines through the
          masks to transfer the patterns onto the wafer.
        </p>
        <p>
          <strong>Etching:</strong> Unprotected areas of the wafer are etched
          away using chemicals or plasma, leaving behind the desired circuit
          patterns.
        </p>
        <p>
          <strong>Doping:</strong> Specific areas of the wafer are exposed to
          dopants to alter their electrical properties.
        </p>
        <p>
          <strong>Deposition:</strong> Layers of materials, such as insulators
          and conductors, are deposited onto the wafer.
        </p>
        <p>
          <strong>Planarization:</strong> The wafer surface is polished again to
          ensure it is flat.
        </p>
        <p>
          <strong>Metallization:</strong> Metal connections are added to the
          wafer to interconnect the circuits.
        </p>
        <p>
          <strong>Testing and Dicing:</strong> The wafer is tested for
          functionality, then sliced into individual chips.
        </p>
        <p>
          <strong>The Role of the Transistor</strong>
        </p>
        <p>
          Transistors are fundamental components of semiconductor chips. They
          function primarily as electronic switches, allowing or blocking the
          flow of electrical current. Modern chips contain millions to billions
          of these tiny switches, enabling them to perform complex calculations
          and logical operations.
        </p>
        <p>
          Transistors also serve as amplifiers, boosting weak signals in devices
          like cell phones. They are crucial in circuits that generate and
          process high-frequency signals, essential for wireless communication
          technologies. The ability to integrate multiple transistors on a
          single piece of semiconductor material was a revolutionary
          breakthrough, laying the groundwork for modern electronics and earning
          Nobel Prizes in 1956 and 2000.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Advancements in Fabrication Technology</strong>
        </p>
        <p>
          The technology behind semiconductor manufacturing has advanced
          rapidly, leading to significant miniaturization. Industry terms like
          '45nm,' '28nm,' and '16nm' refer to the size of the features on the
          chip, with 'nm' standing for nanometer, one-billionth of a meter.
          Smaller feature sizes allow for more transistors on a chip, improving
          performance and efficiency.
        </p>
        <p>Recent advancements include:</p>
        <p>&nbsp;</p>
        <p>
          <strong>FinFET (Fin Field-Effect Transistor):</strong> This 3D
          transistor design allows for better control of the electrical current,
          improving performance and reducing power consumption.
        </p>
        <p>
          <strong>EUV (Extreme Ultraviolet Lithography):</strong> This
          technology uses shorter wavelengths of light to create smaller
          features on the chip, enabling further miniaturization.
        </p>
        <p>
          <strong>3D Stacking:</strong> Chips are now being built in multiple
          layers, allowing for greater density and improved performance.
        </p>
        <p>
          <strong>The Wafer: A Crucial Component</strong>
        </p>
        <p>
          A semiconductor wafer is a thin slice of semiconductor material,
          typically silicon, used to fabricate chips. The manufacturing process
          involves creating multiple chips on a single wafer, similar to
          printing multiple postage stamps on a sheet. The industry standard
          wafer size has grown from 200mm (8 inches) to 300mm (12 inches) in
          diameter, with efforts underway to move to 450mm wafers. Larger wafers
          allow for more chips per batch, reducing production costs and
          increasing efficiency.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Assembly and Testing: Ensuring Quality</strong>
        </p>
        <p>
          Each chip must be packaged and tested once the wafers are diced into
          individual chips. The packaging process involves:
        </p>
        <p>
          <strong>Encapsulation:</strong> Each chip is encased in a protective
          covering.
        </p>
        <p>
          <strong>Wire Bonding:</strong> Tiny wires are connected to the chip to
          allow for power and data transmission.
        </p>
        <p>
          <strong>Testing:</strong> Chips undergo rigorous testing to ensure
          functionality and reliability. This includes verifying their operation
          and subjecting them to stress tests, such as high temperatures and
          voltages.
        </p>
        <p>
          These steps are performed at assembly and test plants, which play a
          critical role in ensuring the quality and performance of the final
          product.
        </p>
        <p>
          <strong>India's Semiconductor Ecosystem</strong>
        </p>
        <p>
          India has a well-established chip design industry, leveraging
          computer-aided design (CAD) tools to create semiconductor chips
          entirely in software. Skilled engineers specify the chip's
          functionality, translate it into electronic circuits, validate the
          design, and optimize it for speed, power consumption, and size. The
          final design is then sent to a fabrication plant for manufacturing.
        </p>
        <p>
          India's move into semiconductor manufacturing will benefit from its
          existing expertise in chip design, which has been supported by a
          steady supply of electronics and computer engineers. The
          interdisciplinary nature of semiconductor manufacturing presents
          opportunities for professionals from various fields, including process
          and control engineering, data science, materials science, physics, and
          chemical engineering.
        </p>
        <p>
          By investing in semiconductor manufacturing, India aims to become a
          significant player in the global semiconductor industry, driving
          technological innovation and self-reliance.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Key Developments by GoI:</strong>
        </p>
        <p>
          <strong>TATA and PSMC Partnership:</strong> Establishing a 300mm wafer
          fabrication plant in Gujarat.
        </p>
        <p>
          <strong>Government Approvals:</strong> New assembly and test plants in
          Gujarat and Assam.
        </p>
        <p>
          <strong>India's Growing Role:</strong> Leveraging existing chip design
          expertise for manufacturing expansion.
        </p>
      </>
    ),
    createdAt: "01 JAN, 2024",
    createdBy: "Admin",
  },
  {
    id: 2,
    imageUrl: blog_2,
    title:
      "Understanding AI and Generative AI: Transforming Computer Programming",
    description: (
      <>
        <p>
          <strong>What is AI?</strong>
        </p>
        <p>
          Artificial Intelligence (AI) is the field of computer science focused
          on creating systems capable of performing tasks that typically require
          human intelligence. This includes a wide range of activities such as
          recognizing speech, making decisions, understanding natural language,
          and interpreting visual data. AI incorporates various subfields, each
          with its unique focus and methodologies:
        </p>
        <p>
          <strong>Machine Learning (ML):</strong> A method of data analysis that
          automates analytical model building. Using algorithms that learn from
          data, ML enables computers to find hidden insights without being
          explicitly programmed where to look.
        </p>
        <p>
          <strong>Natural Language Processing (NLP):</strong> The ability of a
          computer program to understand, interpret, and produce human language.
          This includes tasks like language translation, sentiment analysis, and
          chatbots.
        </p>
        <p>
          <strong>Computer Vision</strong>: The field that enables machines to
          interpret and make decisions based on visual inputs from the world,
          such as images and videos.
        </p>
        <p>
          <strong>Robotics:</strong> The branch of AI involving the design,
          construction, and operation of robots, which are programmed to carry
          out tasks autonomously or semi-autonomously.
        </p>
        <p>
          The goal of AI is to build systems that can operate independently and
          improve over time through experience, thereby increasing their
          efficiency and effectiveness in performing complex tasks.
        </p>
        <p>
          <strong>
            <u>What is Generative AI?</u>
          </strong>
        </p>
        <p>
          <a
            href="https://botscrew.com/blog/generative-ai-examples/"
            style={{ color: "blue" }}
          >
            https://botscrew.com/blog/generative-ai-examples/
          </a>
        </p>
        <p>
          Generative AI is a specialized branch of artificial intelligence that
          focuses on creating new content. Unlike traditional AI, which might
          classify data or make predictions based on existing data, generative
          AI models produce new data. These models are trained on large datasets
          to understand the underlying patterns and structures, enabling them to
          generate original content that mimics human creation. Key types of
          generative AI include:
        </p>
        <p>
          <strong>
            <u>Generative Adversarial Networks (GANs):</u>
          </strong>{" "}
          These consist of two neural networks&mdash;the generator and the
          discriminator&mdash;that work together to produce data that is
          indistinguishable from real data.
        </p>
        <p>
          <strong>
            <u>Variational Autoencoders (VAEs):</u>
          </strong>{" "}
          These models learn the underlying distribution of data to generate new
          samples similar to the input data.
        </p>
        <p>
          <strong>
            <u>Transformers:</u>
          </strong>{" "}
          Large-scale models like GPT (Generative Pre-trained Transformer) are
          capable of generating human-like text based on the context provided.
        </p>
        <p>
          <strong>
            <u>How is Generative AI Useful for Computer Programming?</u>
          </strong>
        </p>
        <p>
          Generative AI is transforming computer programming by automating
          routine tasks, enhancing code quality, and enabling more creative and
          efficient development processes. Here are the detailed ways in which
          generative AI is making an impact:
        </p>
        <p>
          <strong>
            <u>Code Generation and Automation:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Accelerated Development</u>
          </strong>
          : Generative AI can automatically generate code snippets or entire
          programs based on high-level descriptions provided by developers. This
          significantly speeds up the development process, reducing the time
          spent on writing routine or repetitive code.
        </p>
        <p>
          <strong>
            <u>Boilerplate Code Generation:</u>
          </strong>{" "}
          Many programming tasks involve writing boilerplate code, which is
          necessary but not intellectually challenging. AI can automate the
          creation of this boilerplate code, allowing developers to focus on
          more complex and innovative aspects of their projects.
        </p>
        <p>
          <strong>
            <u>Debugging and Error Detection:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Automated Debugging:</u>
          </strong>{" "}
          Generative AI can analyze code to identify potential errors and
          suggest corrections. This capability reduces the time spent on
          debugging and enhances the reliability of the code by catching issues
          that might be missed by human developers.
        </p>
        <p>
          <strong>
            <u>Real-time Code Suggestions</u>
          </strong>
          : While developers are writing code, AI can provide real-time
          suggestions and corrections, helping to avoid common mistakes and
          improving overall code quality.
        </p>
        <p>
          <strong>
            <u>Learning and Skill Enhancement:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>AI-Powered Educational Tools:</u>
          </strong>{" "}
          Generative AI can facilitate learning by offering personalized
          tutorials and instant feedback. These tools can adapt to the learning
          pace of individual developers, providing a customized learning
          experience.
        </p>
        <p>
          <strong>
            <u>Assistance in Code Reviews:</u>
          </strong>{" "}
          AI can assist in code reviews by suggesting improvements and ensuring
          that the code adheres to best practices and coding standards. This not
          only speeds up the review process but also enhances the quality of the
          code.
        </p>
        <p>
          <strong>
            <u>Documentation and Comments:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Automated Documentation:</u>
          </strong>{" "}
          Generative AI can automatically generate comprehensive documentation
          for codebases, making it easier for new developers to understand and
          contribute to existing projects. Good documentation is essential for
          maintaining and scaling software projects.
        </p>
        <p>
          <strong>
            <u>Inline Code Comments:</u>
          </strong>{" "}
          AI can create detailed inline comments that explain the functionality
          and purpose of specific code segments. This improves code readability
          and maintainability, making it easier for other developers to work
          with the code.
        </p>
        <p>
          <strong>
            <u>Prototyping and Ideation:</u>
          </strong>
        </p>
        <p>
          Rapid Prototyping: Developers can use generative AI to quickly
          prototype new features and applications. AI can generate different
          versions of a prototype, allowing developers to experiment with
          various approaches and select the best one.
        </p>
        <p>
          <strong>
            <u>Innovative Solutions:</u>
          </strong>{" "}
          AI can propose innovative solutions to programming challenges by
          offering alternative methods that human developers might not have
          considered. This can lead to more efficient and effective solutions.
        </p>
        <p>
          <strong>
            <u>Language Translation and Conversion:</u>
          </strong>
        </p>
        <p>
          Code Translation: Generative AI can translate code from one
          programming language to another. This is particularly useful for
          projects that need to be migrated to different platforms or updated to
          newer languages.
        </p>
        <p>
          <strong>
            <u>Legacy Code Refactoring:</u>
          </strong>{" "}
          AI can help modernize and refactor legacy codebases, improving
          performance, maintainability, and compatibility with contemporary
          systems. This is crucial for extending the life of older software
          systems and integrating them with modern technologies.
        </p>
        <p>
          In conclusion, AI and generative AI are profoundly enhancing the field
          of computer programming. They automate routine tasks, improve code
          quality, facilitate learning, and foster innovation. By enabling
          developers to work more efficiently and effectively, these
          technologies are driving the evolution of software development and
          expanding the possibilities within the field.
        </p>
      </>
    ),
    createdAt: "03 Feb, 2024",
    createdBy: "Admin",
  },
  {
    id: 3,
    imageUrl: blog_3,
    title: "Veo: Google DeepMind's Advanced Video Generation Model",
    description: (
      <>
        <p>
          <strong>
            <u>Overview of Veo</u>
          </strong>
        </p>
        <p>
          Veo is Google DeepMind's most advanced video generation model to date.
          This cutting-edge technology is designed to produce high-quality
          videos that can surpass a minute in length, maintaining a resolution
          of 1080p. The model is capable of creating content in a diverse array
          of cinematic and visual styles, making it a versatile tool for various
          applications.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Key Features of Veo</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>High-Quality Resolution:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>1080p Video Generation:</u>
          </strong>{" "}
          Veo can generate videos with a resolution of 1080p, which is the
          standard for high-definition video. This ensures that the content is
          sharp, clear, and suitable for both professional and consumer needs.
          High-resolution videos are essential for applications that require
          detailed visuals, such as filmmaking, advertising, and educational
          content.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Extended Video Length:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Beyond One Minute:</u>
          </strong>{" "}
          Traditional AI models for video generation were often limited to short
          clips of a few seconds. Veo, however, can create videos that extend
          beyond a minute, allowing for more comprehensive and intricate
          storytelling. This capability is particularly useful for producing
          longer narratives, detailed demonstrations, and elaborate visual
          effects.
        </p>
        <p>
          <strong>
            <u>Clip Extension:</u>
          </strong>{" "}
          Veo can take an existing short video clip and extend it to 60 seconds
          or more. This involves adding new frames that seamlessly blend with
          the original content, maintaining the same style, context, and
          continuity. This feature is ideal for content creators looking to
          expand their videos without losing the original essence.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Wide Range of Styles:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Cinematic and Visual Styles:</u>
          </strong>{" "}
          Veo is adept at generating videos in various cinematic and visual
          styles, from realistic live-action sequences to animated scenes. It
          can mimic the aesthetic qualities of different genres, including
          action, drama, sci-fi, and fantasy. This versatility makes Veo a
          powerful tool for creators across different industries, enabling them
          to produce content that meets specific artistic visions and standards.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Technical Capabilities</u>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Generative Adversarial Networks (GANs):</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Advanced Architecture:</u>
          </strong>{" "}
          Veo likely uses Generative Adversarial Networks (GANs), which consist
          of two neural networks: the generator and the discriminator. The
          generator creates new content, while the discriminator evaluates it
          against real data. This adversarial process refines the generated
          videos, making them increasingly realistic and coherent.
        </p>
        <p>
          <strong>
            <u>Iterative Refinement:</u>
          </strong>{" "}
          Through multiple iterations, the GANs learn to produce high-quality
          videos that closely resemble the training data, achieving a balance
          between creativity and realism.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Temporal Consistency:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Smooth Transitions</u>
          </strong>
          : One of the key challenges in video generation is ensuring temporal
          consistency, meaning the video flows smoothly from frame to frame
          without abrupt changes. Veo incorporates advanced temporal modeling
          techniques to maintain this consistency, ensuring that movements and
          transitions appear natural and continuous.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Contextual Awareness:</u>
          </strong>{" "}
          Veo understands the context of the scenes it generates, which helps
          maintain coherence in actions, backgrounds, and interactions
          throughout the video. This is crucial for creating believable and
          engaging content.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Contextual Awareness:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Scene Recognition:</u>
          </strong>{" "}
          Veo can identify and maintain the integrity of various elements within
          a scene, such as characters, objects, and backgrounds. This allows it
          to generate content that remains logically and visually consistent
          over time.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Intelligent Adaptation:</u>
          </strong>{" "}
          The model can adapt to different contexts and scenarios, generating
          appropriate actions and interactions that match the overall theme and
          style of the video.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Style Transfer and Adaptation:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Visual Consistency:</u>
          </strong>{" "}
          Veo can apply style transfer techniques to ensure that the visual
          aesthetic remains consistent throughout the video. This involves
          adjusting color palettes, textures, lighting, and other visual
          elements to match the desired artistic style.
        </p>
        <p>
          <strong>
            <u>Creative Flexibility:</u>
          </strong>{" "}
          Users can specify the visual style they want, and Veo will generate
          content that aligns with that vision, providing flexibility for
          creators to experiment with different looks and feels.
        </p>
        <p>Applications of Veo</p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Content Creation for Media:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Social Media:</u>
          </strong>{" "}
          Content creators on platforms like YouTube, Instagram, and TikTok can
          use Veo to produce high-quality videos quickly, enhancing their
          ability to engage with their audience. The extended length and high
          resolution of Veo-generated videos are ideal for creating compelling
          and shareable content.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Professional Filmmaking</u>
          </strong>
          : Filmmakers and studios can leverage Veo to create
          pre-visualizations, special effects, or even complete short scenes.
          This reduces production costs and time, allowing for more
          experimentation and creativity in the filmmaking process.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Advertising and Marketing</u>
          </strong>
          :
        </p>
        <p>
          Engaging Ads: Brands can use Veo to generate engaging video
          advertisements that are visually appealing and tailored to specific
          campaigns. The ability to produce high-quality, extended videos means
          that advertisements can tell a more complete and compelling story,
          potentially increasing their impact and effectiveness.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Educational Content</u>
          </strong>
          :
        </p>
        <p>
          Rich Visuals: Educators and e-learning platforms can create detailed
          and visually rich instructional videos, making learning more
          interactive and effective. Veo's high-resolution and contextually
          aware content can enhance the educational experience by providing
          clear and engaging visual explanations.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Entertainment and Gaming:</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Immersive Experiences:</u>
          </strong>{" "}
          Veo can be used to generate cutscenes, trailers, and in-game
          cinematics, providing a more immersive experience for gamers. The
          ability to produce high-quality, extended video content allows game
          developers to create deeper and more engaging narratives within their
          games.
        </p>
        <p>Future Prospects</p>
        <p>
          <em>
            The capabilities of Veo represent a significant advancement in
            AI-driven video generation, but there is considerable potential for
            further development. Future iterations of Veo could see enhancements
            in
          </em>
          :
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Higher Resolutions:</u>
          </strong>{" "}
          Moving beyond 1080p to 4K or even 8K resolution, providing even
          greater visual fidelity and detail.
        </p>
        <p>
          Longer Videos: Extending the length of generated videos to several
          minutes or more, allowing for even richer storytelling and more
          complex visual narratives.
        </p>
        <p>
          <strong>
            <u>Interactive Content:</u>
          </strong>{" "}
          Creating interactive videos where viewers can influence the storyline
          or outcome, blending gaming and cinematic experiences.
        </p>
        <p>
          In conclusion, Veo by Google DeepMind is a groundbreaking video
          generation model that offers high-resolution video production,
          extended clip lengths, and versatility in visual styles. Its advanced
          technical capabilities make it a powerful tool for various industries,
          pushing the boundaries of what is possible in AI-driven content
          creation.
        </p>
      </>
    ),
    createdAt: "15 MAY, 2024",
    createdBy: "Admin",
  },
  // {
  //   id: 4,
  //   imageUrl: blog_3,
  //   title: "Veo: Google DeepMind's Advanced Video Generation Model",
  //   description:
  //     <>
  //       <p>The latest technology in the car industry encompasses a wide range of innovations aimed at enhancing safety, efficiency, connectivity, and sustainability. Some of the most notable advancements include:</p>
  //       <p><strong>Electric Vehicles (EVs):</strong></p>
  //       <p>Electric vehicles continue to gain momentum, with major automakers investing heavily in the development of electric cars. Advances in battery technology are extending the range of EVs, making them more practical for everyday use. Additionally, the expansion of charging infrastructure is addressing concerns about range anxiety, further driving adoption.</p>
  //       <p>Example: Tesla Model 3</p>
  //       <p>&nbsp;</p>
  //       <p>Pic credit to Tesla</p>
  //       <ul>
  //         <li>The Tesla Model 3 is one of the most popular electric vehicles globally, offering impressive range, acceleration, and technology features. With over-the-air updates, it continuously receives improvements and new features, showcasing the advancements in electric vehicle technology.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p><strong>Autonomous Driving Technology:</strong></p>
  //       <p>Self-driving cars are becoming increasingly sophisticated, thanks to advancements in sensors, artificial intelligence, and machine learning algorithms. While fully autonomous vehicles are still in development, semi-autonomous features like adaptive cruise control, lane-keeping assist, and automated parking are already available in many vehicles.</p>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <ul>
  //         <li>Example: Waymo's Self-Driving Cars</li>
  //       </ul>
  //       <p>Waymo, a subsidiary of Alphabet Inc. (Google's parent company), is a leader in autonomous driving technology. Its self-driving cars have undergone extensive testing and are capable of navigating various environments, including urban streets and highways, demonstrating the progress toward fully autonomous vehicles.</p>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p><strong>Connected Cars:</strong></p>
  //       <p>Connectivity features are transforming the driving experience, allowing vehicles to communicate with each other and with external infrastructure. Connected cars can access real-time traffic information, receive over-the-air updates, and integrate with smart devices for seamless navigation, entertainment, and remote vehicle control.</p>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <ul>
  //         <li>Example: BMW iDrive 7</li>
  //         <li>BMW's iDrive 7 system offers extensive connectivity features, including cloud-based navigation, intelligent personal assistant, and seamless integration with smartphones and smart home devices. It provides drivers with real-time information and personalized services, enhancing the overall driving experience.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p><strong>Advanced Driver Assistance Systems (ADAS):</strong></p>
  //       <p>ADAS technologies are enhancing safety by providing drivers with assistance and warnings to help prevent accidents. These systems include features such as automatic emergency braking, blind-spot monitoring, rear cross-traffic alert, and pedestrian detection.</p>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <ul>
  //         <li>Example: Mercedes-Benz S-Class</li>
  //         <li>The Mercedes-Benz S-Class is equipped with a comprehensive suite of ADAS features, including adaptive cruise control with stop-and-go functionality, active lane-keeping assist, automatic emergency braking, and advanced parking assistance systems. These features enhance safety and convenience for drivers.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p><strong>Vehicle-to-Everything (V2X) Communication:</strong></p>
  //       <p>V2X communication enables vehicles to exchange information with other vehicles, infrastructure, and pedestrians. This technology has the potential to improve traffic flow, reduce congestion, and enhance safety by providing real-time data about road conditions, hazards, and traffic patterns.</p>
  //       <ul>
  //         <li>Example: Cadillac CTS-V2X</li>
  //         <li>Cadillac's CTS-V2X concept car demonstrates the potential of V2X communication technology. It can communicate with other vehicles, traffic signals, and infrastructure to receive real-time data about road conditions, traffic patterns, and potential hazards, enabling proactive safety measures and optimized driving routes.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p><strong>Augmented Reality (AR) Head-Up Displays:</strong></p>
  //       <p>AR head-up displays project vital information, such as speed, navigation instructions, and safety alerts, directly onto the windshield, allowing drivers to keep their eyes on the road while accessing critical information.</p>
  //       <p>&nbsp;</p>
  //       <ul>
  //         <li>Example: BMW's AR Head-Up Display</li>
  //         <li>BMW's AR Head-Up Display projects relevant information, such as navigation instructions, speed limits, and safety alerts, onto the windshield, overlaying it onto the driver's field of view. This allows drivers to access essential information without taking their eyes off the road, improving safety and situational awareness.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p><strong>Advanced Materials and Lightweighting:</strong></p>
  //       <p>Automakers are using advanced materials, such as carbon fiber, aluminum, and high-strength steel, to reduce vehicle weight and improve fuel efficiency without compromising safety or performance.</p>
  //       <ul>
  //         <li>Example: Ford F-150 Aluminum Body</li>
  //         <li>The Ford F-150 features an aluminum-alloy body, reducing weight by up to 700 pounds compared to previous models. This lightweight construction improves fuel efficiency and towing capacity while maintaining durability and strength, showcasing the use of advanced materials in mainstream vehicles.</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p>&nbsp;</p>
  //       <p><strong>Sustainable Materials and Manufacturing:</strong></p>
  //       <p>The car industry is increasingly focused on sustainability, with manufacturers incorporating recycled and renewable materials into vehicle components and adopting eco-friendly manufacturing processes to reduce environmental impact.</p>
  //       <p>&nbsp;</p>
  //       <ul>
  //         <li>Example: Volvo XC60 Reclaimed Plastics</li>
  //         <li>Volvo's XC60 incorporates reclaimed plastics from discarded fishing nets and maritime ropes into various interior components, reducing the environmental impact of manufacturing and contributing to ocean sustainability efforts. This demonstrates the automotive industry's commitment to sustainable practices and materials.</li>
  //         <li>&nbsp;</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p><strong>Vehicle-to-Grid (V2G) Integration:</strong></p>
  //       <p>V2G technology allows electric vehicles to serve as energy storage devices, enabling them to charge during off-peak hours when electricity is cheaper and discharge power back to the grid during peak demand periods, providing grid stability and additional revenue streams for vehicle owners.</p>
  //       <p><strong>&nbsp;</strong></p>
  //       <p><strong>&nbsp;</strong></p>
  //       <ul>
  //         <li>Example: Nissan LEAF V2G Technology</li>
  //         <li>The Nissan LEAF is equipped with V2G technology, allowing it to store excess energy from renewable sources or off-peak electricity and discharge it back to the grid during peak demand periods. This capability helps stabilize the grid, reduce energy costs, and maximize the value of electric vehicles beyond transportation.</li>
  //       </ul>
  //       <p><strong>&nbsp;</strong></p>
  //       <p><strong>Advanced Infotainment Systems:</strong></p>
  //       <p>Modern vehicles are equipped with advanced infotainment systems that offer features such as touchscreen displays, voice recognition, smartphone integration, and streaming services, providing passengers with entertainment, connectivity, and convenience on the go.</p>
  //       <ul>
  //         <li>Example: Audi MMI Touch Response</li>
  //         <li>Audi's MMI Touch Response infotainment system features dual touchscreens with haptic feedback, natural language voice recognition, and seamless integration with smartphones. It offers intuitive control over navigation, entertainment, and vehicle settings, providing passengers with a sophisticated and user-friendly interface.</li>
  //         <li>&nbsp;</li>
  //       </ul>
  //       <p>&nbsp;</p>
  //       <p>These advancements represent just a few examples of the latest technologies shaping the future of the automotive industry, with ongoing research and development efforts continuously driving innovation and improvement.</p>
  //     </>,
  //   createdAt: "05 MAY, 2024",
  //   createdBy: "Admin"
  // },
];
