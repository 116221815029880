import React from "react";
import { hireDeveloper } from "../constants/hireDeveloper";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const HireModalMobile = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-primary to-secondary">
        <Navbar isStickyHeader={false} />
      </div>
      <div className="h-auto w-full inline-block  justify-center align-center bg-white overflow-hidden ">
        <div className="flex flex-wrap justify-center">
          {hireDeveloper?.map((i, index) => (
            <div
              key={index}
              className=" bg-[#FFF] mx-10 md:w-[30%] md:mx-auto h-auto z-30 relative overflow-hidden  px-4 py-2 "
            >
              <Link
                to={`/hire-developer/${i?.developerProfile}`}
                target="_blank"
                className="flex text-[16px] font-extralight hover:text-[#00AAC5] text-left z-[999999999]"
              >
                {i.developerProfile}
              </Link>
            </div>
          ))}
        </div>
        <div className="flex justify-center py-6 flex-col items-center">
          <span className="text-3xl font-semibold font-poppins text-center">
            Experience Excellence with Mappoptimist
          </span>
          <span className="text-base w-2/3 text-center py-4">
            150+ Projects. 200+ Satisfied Clients.
          </span>
          <span className="text-base w-2/3 text-center py-2">
            Trust the leader in digital transformation. With a proven track
            record of excellence, Mappoptimist turns visions into reality. Join
            us and elevate your business today.
          </span>
        </div>
      </div>
    </>
  );
};

export default React.memo(HireModalMobile);
