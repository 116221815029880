import axios from "axios";
import {getAxiosResposeInterceptors} from "../interceptors";

const AxiosService = axios.create({
  baseURL: "https://api.mappoptimist.com",
});

AxiosService.interceptors.request.use(
  async function (request) {
    const token = localStorage.getItem("mappoptimist-crs-token");
    if (token) {
      request.headers["Authorization"] = `Bearer ${ token }`;
    }
    return request;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

AxiosService.interceptors.response.use((response) => {
  return getAxiosResposeInterceptors(response);
});

export default AxiosService;
