import React, {useEffect, useState} from "react";
import HeroSection from "../components/HeroSection";
import About from "../components/About";
import ClientReviews from "../components/ClientReviews";
import ContactSection from "../components/ContactSection";
import ReactGA from "react-ga4";
import Blogs from "../components/Blogs";
import Services from "../components/Services";
import {useLocation} from "react-router-dom";
import SplashScreen from "../components/SplashScreen";

const Home = () => {
  const [showSplash, setShowSplash] = useState(false);
  const location = useLocation();
  let timeout;

  useEffect(() => {
    splashFlag()
    ReactGA.send({
      hitType: "Home Page",
      page: "/",
      title: "Home Page",
    });
    window.scrollTo(0, 0);
    return () => clearTimeout(timeout);
  }, [location]);

  const splashFlag = () => {
    const key = sessionStorage.getItem('splashFlag')
    if (key === "loaded") {
      setShowSplash(false)
    } else {
      sessionStorage.setItem("splashFlag", "loaded")
      setShowSplash(true)
      timeout = setTimeout(() => {
        setShowSplash(false);
      }, 1800);
    }
  }

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center text-black text-3xl my-auto mx-auto  bg-[#F7F5F8]">
        {showSplash ? (
          <SplashScreen />
        ) : (
          <>
            <HeroSection />
            <Services restrictServiceId={["OCR-ICR"]} />
            <About />
            <Blogs />
            <ClientReviews />
            <ContactSection />
          </>
        )}
      </div>
    </>
  );
};

export default Home;
