import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import { motion, useAnimation, useInView } from "framer-motion";
import Services from "../components/Services";
import PortfolioCarausel from "../components/PortfolioCarausel";
import R3 from "../assets/portfolio/R3.webp";
import lower from "../assets/expertise/lower.webp";
import { LatestCreationList } from "../constants/index";
import { servicesList } from "../constants/services";
import { useParams } from "react-router-dom";
import QuoteModal from "../components/QuoteModal";

const ServicesImagePage = () => {
  let containerRef = useRef([]);
  let clearIntervalVal = [];

  const handleMouseEnter = (i) => {
    clearInterval(clearIntervalVal[i]);
    if (containerRef?.[i]) {
      clearIntervalVal[i] = setInterval(() => {
        if (containerRef?.[i]) {
          containerRef[i].scrollTop += 2;
        }
      }, 10);
    }
  };

  const handleMouseLeave = (i) => {
    clearInterval(clearIntervalVal[i]);
    if (containerRef?.[i]) {
      clearIntervalVal[i] = setInterval(() => {
        if (containerRef?.[i]) {
          containerRef[i].scrollTop -= 5;
        }
      }, 10);
    }
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    return () => {
      clearInterval(clearIntervalVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <motion.div
      className="w-screen  relative z-0 py-20 mt-32"
      id="About"
      ref={ref}
      variants={{
        hidden: { opacity: 1, y: 0 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="visible"
      animate={mainControls}
      transition={{ duration: 1.6 }}
    >
      <div
        className="flex flex-col justify-start items-center pb-10 "
        id="Services"
      >
        <h1 className="py-2 text-5xl md:text-6xl font-semibold font-poppins bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
          Our Latest Creation
        </h1>
      </div>
      <div className="flex flex-wrap col-span-3 justify-center items-center w-full md:px-20 px-8 gap-20">
        {LatestCreationList?.map((i, index) => (
          <div
            className="bg-white md:w-[340px] h-[400px] overflow-y-scroll rounded-md  shadow-xl no-scrollbar"
            ref={(ref) => (containerRef[index] = ref)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <img src={i.img} loading="lazy" alt="SEO" className="w-full" />
          </div>
        ))}
      </div>
    </motion.div>
  );
};
const ServicesPage = () => {
  const { serviceId } = useParams();
  const [showModal, setShowModal] = React.useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const [servicesData, setServicesData] = useState();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  React.useEffect(() => {
    const handleScroll = () => {};
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTarget = (id) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const getServiceDetail = () => {
    const detailService = servicesList.filter(
      (item) => serviceId === item.title
    );
    setServicesData(detailService);
  };

  useEffect(() => {
    getServiceDetail();
  }, [serviceId]);

  const onCloseModal = async () => {
    setShowModal(false);
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <QuoteModal
          onPress={(v) => {
            onCloseModal(v);
          }}
        />
      ) : null}
      <Navbar />
      <div className=" flex w-full h-1/2  relative  justify-center items-center">
        <img
          src={servicesData?.[0]?.mainImg}
          alt="services"
          className="w-full h-[800px] object-cover blur-sm bg-gradient-to-r from-primary to-secondary"
        />
        <div className="flex absolute top-10 mt-48 flex-col justify-center items-center px-10 z-20">
          <span className=" text-2xl md:text-6xl font-poppins font-semibold text-white">
            {servicesData?.[0]?.title}
          </span>
          <p className="text-sm md:text-xl font-bold text-[#FCE543]  text-center  md:w-2/3 my-8">
            {servicesData?.[0]?.description}
          </p>
          <button
            className=" text-[20px]  bg-white text-[#146AA1] font-poppins px-10 py-3 rounded-full"
            onClick={() => {
              setShowModal(true);
              scrollToTarget("Contact-Us");
            }}
          >
            Get Quote
          </button>
        </div>
      </div>

      <div className="flex flex-row mx-auto px-10 w-[85%] mt-28 mb-3">
        <div className="flex-none transition ease-in-out duration-500">
          <img
            src={servicesData?.[0]?.subImg}
            alt="img"
            className="md:w-[300px] md:h-auto rounded-lg hidden md:flex"
          />
        </div>
        <div className="grow flex-col transition ease-in-out duration-500">
          <div className="flex justify-center flex-col md:mx-10">
            <span className=" pb-2 text-5xl font-poppins font-semibold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              {servicesData?.[0]?.subTitle}
            </span>
            <span className="text-xl font-light my-8 leading-8">
              {servicesData?.[0]?.whyChooseUs}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-row mx-auto px-10 w-[85%] mb-20">
        <span className="text-xl font-light leading-8">
          {servicesData?.[0]?.whyChooseUsSubTitle}
        </span>
      </div>
      <div className="flex flex-col justify-center align-middle items-center">
        <div className="flex justify-center items-center flex-col md:w-1/2 w-full px-2">
          <span className="text-5xl text-center font-poppins font-semibold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent py-4 ">
            Our Technologies
          </span>
          <span className="line-clamp-2 text-base text-center">
            Competently recaptiualize multifunctional schemas without an
            expanded array of niches. Continually engage cooperative sources
            vis-a-vis web-enabled benefits.
          </span>
        </div>
        <div className="flex w-3/4 flex-wrap">
          <div className="w-[100%]  mx-auto  xxmd:mt-12 z-0 ">
            <motion.div
              className="grid grid-cols-2  midFM:h-[20rem] midlg:h-[30rem] shadow-lg rounded-md  justify-between  p-4 mb-16 xxmd:grid-cols-6"
              ref={ref}
              variants={{
                hidden: { opacity: 0, y: 100 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 1.6 }}
            >
              {servicesData?.[0]?.technology?.map((item) => (
                <div
                  className="flex items-center justify-center w-full "
                  key={item.id}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    key={item.id}
                    className="sm:w-[50%] xsm:w-[70%] xxmd:w-[90%] midxmd:w-[90%]"
                  />
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
      <div>
        {serviceId === "Web Development" && <ServicesImagePage />}
        {serviceId === "UI/UX Design" && <ServicesImagePage />}
        {serviceId === "App Development" && (
          <div className="relative w-screen  overflow-hidden" id="Portfolio">
            <img
              src={lower}
              alt=""
              className="scale-[1.3] w-screen relative z-10 block midFM:hidden"
            />
            <img
              src={R3}
              alt=""
              className="absolute -bottom-[20%] midFM:-top-[22%] z-0"
            />
            <div className="w-[90%] mx-auto p-10">
              <h2 className="font-poppins text-[30px] lmd:text-[48px] flex gap-2 flex-wrap bg-gradient-to-br from-secondary to-primary text-transparent bg-clip-text">
                Our Portfolios
              </h2>
            </div>
            <div className="mx-auto relative z-10 ">
              <PortfolioCarausel page={"portfolio"} />
            </div>
          </div>
        )}
      </div>
      <div>
        <Services
          title="More Services"
          classes="mt-3 mb-4"
          restrictServiceId={serviceId === "AI-ML" ? [] : ["OCR-ICR"]}
        />
      </div>
    </>
  );
};

export default ServicesPage;
