import React from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { useEffect, useRef } from "react";
import image1 from "../assets/hero-section/image1.webp";
import image2 from "../assets/hero-section/image2.webp";
import image3 from "../assets/hero-section/image3.webp";
import image4 from "../assets/hero-section/image4.webp";
import image5 from "../assets/hero-section/image5.webp";
import { motion, useInView, useAnimation } from "framer-motion";
import { Link } from "react-scroll";
import Navbar from "./Navbar";
import pattern from "../assets/hero-section/pattern.webp";
import pattern2 from "../assets/hero-section/pattern2.webp";
import circle from "../assets/hero-section/circle.webp";
import plus from "../assets/hero-section/plus.webp";
import square from "../assets/hero-section/square.webp";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker ";
import AgenciesSlider from "./agenciesSlider";
import Modal from "./ContactModal";
import ServiceWave from "../assets/ServicesWave.png";
import { isMobile } from "react-device-detect";

const HeroSection = () => {
  const gaEventTracker = useAnalyticsEventTracker("Button");
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const scrollToTarget = (id) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <Navbar />
      <div
        className=" w-screen relative -z-1  bg-gradient-to-r from-primary to-secondary pt-32"
        id="Hero-Section"
      >
        <div className="w-full bg-gradient-to-r from-primary to-secondary ">
          <img
            src={pattern}
            alt=""
            className="absolute right-9 bottom-44 md:bottom-36 md:right-20"
          />
          <img src={pattern2} alt="" className="absolute top-36 left-10" />
          <img
            src={plus}
            alt=""
            className="absolute left-[70%] md:left-[35%] top-36"
          />
          <img
            src={circle}
            alt=""
            className="absolute left-12 top-[68%] md:top-[50%]"
          />
          <img
            src={square}
            alt=""
            className="absolute left-[70%] md:right-[3%] top-36 hidden md:block"
          />
          <img
            src={square}
            alt=""
            className="absolute right-[10%] md:left-[43%] bottom-24 md:bottom-36"
          />
          <img
            src={plus}
            alt=""
            className="absolute right-[10%] md:right-[28%] bottom-[25%] md:bottom-32"
          />
        </div>

        <div
          className=" w-[90%] flex flex-col justify-center h-fit mx-auto my-auto gap-2 md:flex-row lmd:justify-between items-center relative z-40 inset-0 "
          id="home"
        >
          <motion.div
            className="md:order-2 flex items-center justify-center w-full"
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: 100 },
              visible: { opacity: 1, x: 0 },
            }}
            initial={isMobile ? "visible" : "hidden"}
            animate={mainControls}
            transition={{ duration: 1.9 }}
          >
            <Modal />
          </motion.div>

          <motion.div
            className="flex flex-col gap-4 w-full lmd:gap-6 items-start justify-start"
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: -100 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1.9 }}
          >
            <div className="w-full font-poppins flex text-3xl xmd:text-4xl midlg:text-5xl leading-loose flex-col middle:ml-12">
              <h1 className="font-RobotoSlab flex gap-2 flex-wrap text-white leading-loose">
                Transforming Ideas into Digital Marvels: Your Vision, Our
                Expertise!
              </h1>
            </div>

            <div className="flex justify-center flex-wrap items-center mr-10 sm:mr-24 lmd:mr-0 mx-auto gap-2 md:flex-row md:justify-start md:mx-0 mt-4">
              <Link
                to="Developer"
                smooth={true}
                offset={100}
                duration={1000}
                onClick={() => {
                  gaEventTracker("Know More Btn", "Clicked from Home Page");
                }}
              >
                <button
                  className="flex items-center mt-2 gap-3 justify-center bg-[#051659]  border py-[6px] px-[12px] rounded-full cursor-pointer text-[22px] font-medium text-white font-poppins500 ml-12 "
                  onClick={() => scrollToTarget("Services")}
                >
                  Explore services
                  <span className="know">
                    <BsArrowRightCircleFill className="text-white bg-black rounded-full" />
                  </span>
                </button>
              </Link>
              <Link to="Developer" smooth={true} offset={75} duration={1000}>
                <div className=" flex ml-20 sm:ml-10 mt-4 lmd:mt-2 cursor-pointer ">
                  <span className=" left-[-10px] ml-[-18px] z-1 hidden sm:block">
                    <img src={image1} alt="rounded clients" className=" w-12" />
                  </span>
                  <span className=" left-[-10px] ml-[-18px] z-1">
                    <img src={image2} alt="" className=" w-12" />
                  </span>
                  <span className=" left-[-10px] ml-[-18px] z-1">
                    <img src={image3} alt="" className=" w-12" />
                  </span>
                  <span className=" left-[-10px] ml-[-18px] z-1">
                    <img src={image4} alt="" className=" w-12" />
                  </span>
                  <span className=" left-[-10px] ml-[-18px] z-1">
                    <img src={image5} alt="" className=" w-12" />
                  </span>
                  <div className=" left-[-10px] ml-[-18px] z-1 w-12 h-12 rounded-full bg-[#5871D7] text-[14px] text-white flex items-center justify-center leading-[12px]">
                    +100
                  </div>
                </div>
              </Link>
            </div>
          </motion.div>
        </div>
        <AgenciesSlider />
        <img src={ServiceWave} className="h-auto w-full " alt="wave" />
      </div>
    </>
  );
};

export default HeroSection;
