import React from "react";
import GmailIcon from "../assets/navbar/Gmail.png";
import WhatsAppIcon from "../assets/navbar/Whatsapp-Icon.png";
import ARSVG from "../assets/navbar/ar.svg";
import NLSVG from "../assets/navbar/nl.svg";
import ENSVG from "../assets/navbar/en-us.svg";
import DESVG from "../assets/navbar/de.svg";

const TopNavbar = () => {
  const countries = [
    { name: "AR", image: ARSVG },
    { name: "NL", image: NLSVG },
    { name: "EN", image: ENSVG },
    { name: "DE", image: DESVG },
  ];

  const renderCountry = () => {
    return countries?.map((country, index) => {
      return (
        <li
          className={`flex text-[20px] cursor-pointer items-center leading-[28px] font-poppins`}
        >
          <img
            src={country?.image}
            alt="logo"
            className={`sm:w-[20px] w-[26px]`}
            loading="lazy"
          />
          <p
            className={`font-poppins text-[14px] font-semibold leading-9 pl-1 ${
              index === 3 ? "" : "pr-3"
            }`}
          >
            {country?.name}
          </p>
        </li>
      );
    });
  };
  const onWhatsappClicked = () => {
    window.open("https://wa.me/917011529345?text=", "_blank");
  };
  return (
    <>
      <div className={`w-screen z-50 bg-white`}>
        <nav
          className={
            "flex items-center mx-auto  md:w-[90%] w-[100%] relative sm:relative z-50"
          }
        >
          <div className="w-full flex justify-between items-start px-2 py-1">
            <div className="flex items-center cursor-pointer bg-transparent">
              <img
                src={GmailIcon}
                alt="logo"
                className={`w-[30px]`}
                loading="lazy"
              />
              <p className="font-poppins hidden md:flex text-[14px] font-semibold leading-9 pl-2">
                business@mappoptimist.com
              </p>
              <div className="text-[24px] font-semibold text-black font-poppins leading-9 pl-2 pr-1">
                |
              </div>
              <img
                onClick={() => {
                  onWhatsappClicked();
                }}
                src={WhatsAppIcon}
                alt="logo"
                className={`w-[36px]`}
                loading="lazy"
              />
              <p
                onClick={() => {
                  onWhatsappClicked();
                }}
                className="font-poppins hidden md:flex text-[14px] font-semibold leading-9 pl-1"
              >
                +91 7011529345
              </p>
            </div>
            <ul className="list-none flex lmd:text-[12px] order-3 self-center z-50">
              {renderCountry()}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default TopNavbar;
