import "react-phone-number-input/style.css";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { hireDeveloperSchema } from "../validation";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const Modal = () => {
  const { developerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const DeveloperFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      requirement: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values) {
        handleSubmit(values);
      }
    },
    validationSchema: hireDeveloperSchema(),
  });

  const handleSubmit = async (value) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.mappoptimist.com/v1/send-email-hireDeveloper",
        { ...value, techRequired: developerId }
      );
      if (response?.status === 200) {
        toast.success("Email send successfully");
        DeveloperFormik.resetForm();
        setIsLoading(false);
      } else {
        toast.error("Email not sent");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Email not sent");
    }
  };

  return (
    <>
      <div className="relative mt-10 md:w-4/5 md:mx-auto w-full">
        <div className=" border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex md:px-8  flex-col justify-center align-middle mx-8 md:mx-0 transition ease-in-out duration-500">
            <span className="text-3xl font-semibold text-black mb-2 my-4">
              Request a FREE quote
            </span>
          </div>
          <form
            className="w-auto my-4 mx-8 md:mx-0 pb-6 md:px-8"
            onSubmit={DeveloperFormik.handleSubmit}
          >
            <input
              type="text"
              className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[16px] outline-none my-2 py-4 ${
                DeveloperFormik.touched.name && DeveloperFormik.errors?.name
                  ? "border-red-500"
                  : "border-[#929292]"
              }`}
              placeholder="First Name *"
              onChange={(event) => {
                DeveloperFormik.setFieldValue("name", event?.target?.value);
              }}
              value={DeveloperFormik.values?.name}
            />
            {DeveloperFormik.touched.name && DeveloperFormik.errors?.name ? (
              <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                {DeveloperFormik.errors?.name}
              </p>
            ) : null}
            <input
              type="text"
              className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[16px] outline-none my-2 py-4 ${
                DeveloperFormik.touched.email && DeveloperFormik.errors?.email
                  ? "border-red-500"
                  : "border-[#929292]"
              }`}
              placeholder="Email Address *"
              onChange={(event) => {
                DeveloperFormik.setFieldValue("email", event?.target?.value);
              }}
              value={DeveloperFormik.values?.email}
            />
            {DeveloperFormik.touched.email && DeveloperFormik.errors?.email ? (
              <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                {DeveloperFormik.errors?.email}
              </p>
            ) : null}
            <label className="text-xs font-semibold">
              Phone Number <span className="text-red-400">*</span>
            </label>
            <PhoneInput
              name="phoneInput"
              international
              defaultCountry="US"
              onChange={(val) => {
                DeveloperFormik.setFieldValue("phone", val);
              }}
              value={DeveloperFormik.values?.phone}
              className={`input-phone-number border-b rounded w-full text-black text-base placeholder:text-black placeholder:text-[16px] outline-none my-2 pb-4 ${
                DeveloperFormik.touched.contact &&
                DeveloperFormik.errors?.contact
                  ? "border-red-500"
                  : "border-[#929292]"
              }`}
            />
            {DeveloperFormik.touched.phone && DeveloperFormik.errors?.phone ? (
              <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                {DeveloperFormik.errors?.phone}
              </p>
            ) : null}
            <input
              type="text"
              className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[16px] outline-none my-2 py-4 ${
                DeveloperFormik.touched.requirement &&
                DeveloperFormik.errors?.requirement
                  ? "border-red-500"
                  : "border-[#929292]"
              }`}
              placeholder="Requirement"
              onChange={(event) => {
                DeveloperFormik.setFieldValue(
                  "requirement",
                  event?.target?.value
                );
              }}
              value={DeveloperFormik.values?.requirement}
            />
            {DeveloperFormik.touched.requirement &&
            DeveloperFormik.errors?.requirement ? (
              <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                {DeveloperFormik.errors?.requirement}
              </p>
            ) : null}

            <div className="flex justify-start my-4">
              <button
                disabled={isLoading}
                class="bg-[#0CBAE1] text-white text-base py-2 px-2 rounded w-[180px] mt-4  font-semibold flex items-center justify-center"
                onSubmit={DeveloperFormik.handleSubmit}
              >
                {isLoading && (
                  <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#FCE543"
                    secondaryColor="#FFF"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    strokeWidth={5}
                    wrapperClass="mr-2"
                  />
                )}{" "}
                Get a free quote
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Modal;
