import React from "react";
import { useEffect, useRef } from "react";
import { useAnimation, useInView } from "framer-motion";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { testimonials } from "../constants";
import TestimonialsItem from "../components/TestimonialsItem";
import TestimonialWave from "../assets/testimonialWave.png";

const ClientReviews = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 1.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      <div className="w-screen h-auto py-8   relative bg-[#C0E7F1]">
        {/* <img
          src={TestimonialWave}
          className="h-auto w-screen top-0s"
          alt="wave"
        /> */}

        <div className="w-[80%]  mx-auto">
          <div className="flex justify-start ml-2 md:-ml-14">
            <span className="text-center md:py-3 font-poppins lmd:text-[48px] font-semibold text-[30px] bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Testimonial
            </span>
          </div>
          <div className="flex justify-start ml-2 md:-ml-14  ">
            <p className="text-xl middle:text-2xl p-1 font-poppins">
              See what they say about us
            </p>
          </div>
        </div>
        <div className="mt-10 w-[90%] middle:w-[95%] mx-auto h-full ">
          <Carousel
            responsive={responsive}
            arrows={false}
            infinite={true}
            sliderClass={true}
            showDots={false}
            autoPlay={true}
            autoPlaySpeed={4000}
          >
            {testimonials.map((testimonial, index) => (
              <TestimonialsItem testimonial={testimonial} key={index} />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ClientReviews;
