import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import BlogCard from "../components/BlogCard";
import Navbar from "../components/Navbar";
import useFetchBlogs from "../hooks/useFetchBogs";
import {blogsData} from "../constants";
import {CiCalendar} from "react-icons/ci";

const DetailedBlog = () => {
  let {blogsId} = useParams();

  const [res, setRes] = useState([]);
  const [next, setNext] = useState([]);
  const {searchBlogs} = useFetchBlogs();

  const filterData = () => {
    const result = blogsData?.filter((blog) => blog.title === blogsId);
    const nextres = blogsData?.filter((blog) => blog.title !== blogsId);
    setRes(result);
    setNext(nextres);
  };

  useEffect(() => {
    if (blogsData?.length) {
      filterData();
    }
  }, [blogsData, blogsId]);

  useEffect(() => {
    searchBlogs();
  }, []);

  return (
    <>
      <div className=" w-screen relative -z-1  bg-transparent">
        <div className="bg-gradient-to-r from-primary to-secondary  z-50 fixed top-0 w-full">
          <Navbar isStickyHeader={true} />
        </div>

        <div className="about w-screen h-[100%] z-10 justify-center md:mt-20 md:px-40 md:pt-10 pt-20 px-10">
          <div className="mx-auto mt-10">
            <div className="w-full h-[500px] py-10 overflow-hidden">
              <img
                src={res?.[0]?.imageUrl}
                alt=""
                className="w-full h-full object-cover rounded-md"
              />
            </div>
            <div className="flex divide-x divide-black gap-4 -mt-4  h-4 items-center">
              <div className="flex items-center">
                <CiCalendar className="h-6 w-6 mr-2" />
                <span className="text-base font-extralight text-gray-600 ">
                  {/* {Number(moment().format("DD"))}
                  {""} {moment().format("MMM")}, {moment().format("YYYY")} */}
                  {res?.[0]?.createdAt}
                </span>
              </div>
              <span className="text-base font-extralight text-gray-600 px-4">
                {res?.[0]?.createdBy}
              </span>
            </div>
          </div>
          <h1 className="text-black text-lg md:text-[30px] text-[30px] font-poppins mt-6">
            {res?.[0]?.title}
          </h1>
          <div>
            <h1 className="text-black text-xl pt-4 ">
              {res?.[0]?.description}
            </h1>
          </div>
          <div className="py-12">
            <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-5xl font-poppins">
              Related Blogs
            </span>
            <div className="flex flex-wrap justify-center py-4  gap-2">
              {next?.map((item) => (
                <div className="flex flex-wrap  bg-white justify-center  sm:w-[48%] md:w-[1/3] mx-1  shadow-[10px_20px_50px_-10px_rgba(0,0,0,0.6)] rounded-md my-6 p-4 lg:w-[32%]">
                  <div className="space-x-8 bg-white gap-12   rounded-md  justify-between  overflow-hidden    z-30">
                    <BlogCard key={item?._id} blog={item} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedBlog;
