"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React from "react";
import BlogCard from "./BlogCard";
import { blogs } from "../constants/index";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import useFetchBlogs from "../hooks/useFetchBogs";
import { blogsData } from "../constants/index";
import Carousel from "react-multi-carousel";

const MySwiperComponent = () => {
  const [totalSlides, setTotalSlides] = React.useState(2);
  const { blogList, searchBlogs } = useFetchBlogs();

  const getTotalSlides = (v) => {
    if (v?.width <= 480) {
      setTotalSlides(Number(v?.slides?.length));
    } else if (v?.width > 480 && v?.width <= 740) {
      setTotalSlides(Number(v?.slides?.length - 1));
    } else if (v?.width > 740 && v?.width <= 1275) {
      setTotalSlides(Number(v?.slides?.length - 2));
    } else if (v?.width > 1275) {
      setTotalSlides(Number(v?.slides?.length - 2));
    }
  };

  const ref = useRef(null);

  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  useEffect(() => {
    searchBlogs();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 1.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      <div className="flex-wrap w-[90%] mx-auto relative flex group z-50 mb-10 ">
        {/* {blogsData?.length ? (
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={(v) => {
              getTotalSlides(v);
            }}
            pagination={{ clickable: true, dynamicBullets: true }}
            breakpoints={{
              480: { slidesPerView: 2 },
              740: { slidesPerView: 3 },
              1275: { slidesPerView: 3 },
            }}
            centerInsufficientSlides={true}
            className="flex"
          >
            <div className="flex-wrap mx-auto relative group space-x-8 ">
              {blogsData?.slice(0, 6)?.map((item) => (
                <motion.div
                  className=" bg-white gap-12 midlg:h-[30rem] shadow-sm rounded-md  justify-between  p-4  mb-16 z-30 "
                  ref={ref}
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ duration: 2.5 }}
                >
                  <SwiperSlide key={item?.title} id="swiper-item-id">
                    <div className="space-x-8 bg-white gap-12 midlg:h-[30rem] shadow-sm rounded-md  justify-between  p-0  mb-16 z-30">
                      <BlogCard blog={item} />
                    </div>
                  </SwiperSlide>
                </motion.div>
              ))}
            </div>
          </Swiper>
        ) : null} */}

        <div className="mt-10 w-[90%] middle:w-[95%] mx-auto h-full ">
          <Carousel
            responsive={responsive}
            arrows={true}
            infinite={true}
            sliderClass={true}
            showDots={false}
            // autoPlay={true}
            // autoPlaySpeed={8000}
          >
            {blogsData.map((item, index) => (
              <div className="space-x-8 bg-white gap-12 midlg:h-[30rem] shadow-xl rounded-md  justify-between  p-0  mb-10 z-30 mx-4 overflow-hidden">
                <BlogCard blog={item} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default MySwiperComponent;
