import React, {useEffect, useRef} from "react";
import {motion, useAnimation, useInView} from "framer-motion";
import {BsArrowRightCircleFill} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {CiCalendar} from "react-icons/ci";

const BlogCard = (props) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <>
      <motion.div
        className="h-auto z-30 relative overflow-hidden"
        ref={ref}
        variants={{
          hidden: {opacity: 0, y: 100},
          visible: {opacity: 1, y: 0},
        }}
        initial="hidden"
        animate={mainControls}
        transition={{duration: 2.5}}
        onClick={() => navigate(`/blog-details/${ props?.blog?.title }`)}
      >
        <div className="h-[250px] w-full overflow-hidden rounded-md hover:rounded-md flex">
          <img
            src={props?.blog?.imageUrl}
            alt={props?.blog?.title}
            id="slider-img"
            className="w-full hover:rounded-md  rounded-md  hover:scale-125 ease-in duration-500 object-cover"
          />
        </div>
        <div className="px-4">
          <div className="flex divide-x divide-black gap-4  mt-4  h-4 items-center">
            <div className="flex items-center">
              <CiCalendar className="h-6 w-6 mr-2" />
              <span className="text-base font-extralight text-gray-600 ">
                {/* {Number(moment().format("DD"))}
                {""} {moment().format("MMM")}, {moment().format("YYYY")} */}
                {props?.blog?.createdAt}
              </span>
            </div>
            <span className="text-base font-extralight text-gray-600 px-4">
            {props?.blog?.createdBy}
            </span>
          </div>
          <h1 className="font-poppins text-xl mt-4 mb-4 line-clamp-2">
            {props?.blog?.title}
          </h1>
          <h1 className="font-poppins text-sm  text-gray-700 line-clamp-3">
            {props?.blog?.description}
          </h1>
          <div className="flex justify-start pr-5 ">
            <button className="flex items-center mt-2 gap-3 justify-center  py-[6px] rounded-full cursor-pointer text-lg font-medium text-blue-800 font-poppins  hover:scale-125 ease-in duration-500 underline ">
              Read more
              <span className="hover:scale-125 ease-in duration-500">
                <BsArrowRightCircleFill className="text-blue-800 bg-transparent rounded-full" />
              </span>
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default BlogCard;
