import "react-phone-number-input/style.css";
import React from "react";
import PhoneInput from "react-phone-number-input";
import { ContactUsSchema } from "../validation";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Oval } from "react-loader-spinner";

const QuoteModal = (props) => {
  const [quote, setQuote] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const ContactFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      services: "",
      budget: "",
      description: "",
      technology: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values) {
        handleContactSubmit(values);
      }
    },
    validationSchema: ContactUsSchema(quote),
  });

  const handleContactSubmit = async (value) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.mappoptimist.com/v1/send-email-getQuote",
        { ...value, isQuote: quote }
      );
      if (response.status === 200) {
        setIsLoading(false);

        toast.success("Email send successfully");
        ContactFormik.resetForm();
      } else {
        setIsLoading(false);

        toast.error("Email not sent");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Email not sent");
    }
  };

  return (
    <>
      <div
        className="backdrop-brightness-50 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={() => props?.onPress()}
      >
        <div
          className="relative mt-10 md:w-[600px] md:mx-auto w-full mx-4 pb-12 h-[75%]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-around border-b border-[#929292] text-[20px] font-semibold mb-4">
              <div className="flex  w-full border-r border-[#929292] justify-center transition ease-in-out duration-500">
                <button
                  className={`items-center ${
                    quote ? "text-blue-700 text-[22px]" : "text-black"
                  } font-poppins my-2`}
                  onClick={() => {
                    setQuote(true);
                    ContactFormik.resetForm();
                  }}
                >
                  Get Quote{" "}
                </button>
              </div>
              <div className="flex  w-full justify-center transition ease-in-out duration-500">
                <button
                  className={`items-center  ${
                    quote ? "text-black" : "text-blue-700 text-[22px]"
                  } font-poppins`}
                  onClick={() => {
                    setQuote(false);
                    ContactFormik.resetForm();
                  }}
                >
                  Hire Individual{" "}
                </button>
              </div>
            </div>
            <div className="flex md:px-8  flex-col justify-center align-middle mx-8 md:mx-0 transition ease-in-out duration-500">
              <span className="text-xl font-semibold text-black mb-2">
                {quote
                  ? "Get consultant with Experts!"
                  : "Get quote individual developer"}
              </span>
              <span className="text-sm">
                Get 30 Minutes of Free consultation from field Experts.
              </span>
            </div>
            <form
              className="w-auto my-4 mx-8 md:mx-0 pb-6 md:px-8"
              onSubmit={ContactFormik.handleSubmit}
            >
              <input
                type="text"
                className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[16px] outline-none my-2 py-2 ${
                  ContactFormik.touched.name && ContactFormik.errors?.name
                    ? "border-red-500"
                    : "border-[#929292]"
                }`}
                placeholder="First Name *"
                onChange={(event) => {
                  ContactFormik.setFieldValue("name", event?.target?.value);
                }}
                value={ContactFormik.values?.name}
              />
              {ContactFormik.touched.name && ContactFormik.errors?.name ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                  {ContactFormik.errors?.name}
                </p>
              ) : null}
              <input
                type="text"
                className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[16px] outline-none my-2 py-2 ${
                  ContactFormik.touched.email && ContactFormik.errors?.email
                    ? "border-red-500"
                    : "border-[#929292]"
                }`}
                placeholder="Email Address *"
                onChange={(event) => {
                  ContactFormik.setFieldValue("email", event?.target?.value);
                }}
                value={ContactFormik.values?.email}
              />
              {ContactFormik.touched.email && ContactFormik.errors?.email ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                  {ContactFormik.errors?.email}
                </p>
              ) : null}
              <label className="text-xs font-semibold">
                Phone Number <span className="text-red-400">*</span>
              </label>
              <PhoneInput
                name="phoneInput"
                international
                defaultCountry="US"
                onChange={(val) => {
                  ContactFormik.setFieldValue("contact", val);
                }}
                value={ContactFormik.values?.contact}
                className={`input-phone-number border-b rounded w-full text-black text-base placeholder:text-black placeholder:text-[16px] outline-none my-2 pb-2 ${
                  ContactFormik.touched.contact && ContactFormik.errors?.contact
                    ? "border-red-500"
                    : "border-[#929292]"
                }`}
              />
              {ContactFormik.touched.contact &&
              ContactFormik.errors?.contact ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito -mt-2">
                  {ContactFormik.errors?.contact}
                </p>
              ) : null}
              {quote ? (
                <div class="relative  text-black">
                  <label
                    for="frm-services"
                    className="text-[#A4A4A4] text-[14px] font-normal leading-6"
                  >
                    Services <span className="text-red-400">*</span>
                  </label>
                  <select
                    className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[14px] outline-none my-2 ${
                      ContactFormik.touched.services &&
                      ContactFormik.errors?.services
                        ? "border-red-500"
                        : "border-[#929292]"
                    }`}
                    name="services"
                    id="frm-whatever"
                    onChange={(event) => {
                      ContactFormik.setFieldValue(
                        "services",
                        event?.target?.value
                      );
                    }}
                    value={ContactFormik.values?.services}
                  >
                    <option value="">Select</option>
                    <option value="Mobile App Development">
                      Mobile App Development
                    </option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="Website Design">Website Design</option>
                    <option value="Mobile App Design">Mobile App Design</option>
                    <option value="Product Strategy">Product Strategy</option>
                    <option value="Others">Others</option>
                  </select>
                  {ContactFormik.touched.services &&
                  ContactFormik.errors?.services ? (
                    <p className="text-red-500 text-sm italic font-normal font-nunito -mt-1">
                      {ContactFormik.errors?.services}
                    </p>
                  ) : null}
                </div>
              ) : (
                <div class="relative  text-black">
                  <label className="text-[#A4A4A4] text-[14px] font-normal leading-6">
                    Technology <span className="text-red-400">*</span>
                  </label>
                  <select
                    className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[14px] outline-none my-2 ${
                      ContactFormik.touched.services &&
                      ContactFormik.errors?.services
                        ? "border-red-500"
                        : "border-[#929292]"
                    }`}
                    name="technology"
                    id="frm-whatever"
                    onChange={(event) => {
                      ContactFormik.setFieldValue(
                        "technology",
                        event?.target?.value
                      );
                    }}
                    value={ContactFormik.values?.technology}
                  >
                    <option value="">Select</option>
                    <option value="MERN Stack">MERN Stack </option>
                    <option value="Full Stack">Full Stack </option>
                    <option value="Mean Stack">Mean Stack</option>
                    <option value="Laravel">Laravel</option>
                    <option value="Python">Python</option>
                    <option value="React Js">React Js </option>
                    <option value="UI/UX Developer">UI/UX Developer </option>
                    <option value="Java Developer">Java Developer</option>
                    <option value="Node js">Node js</option>
                    <option value="Next js">Next js</option>
                    <option value="Android">Android</option>
                    <option value="Swift">Swift</option>
                    <option value="Others">Others</option>
                  </select>
                  {ContactFormik.touched.technology &&
                  ContactFormik.errors?.technology ? (
                    <p className="text-red-500 text-sm italic font-normal font-nunito -mt-1">
                      {ContactFormik.errors?.technology}
                    </p>
                  ) : null}
                </div>
              )}

              <div class="relative  text-black">
                <label
                  for="frm-services"
                  className="text-[#A4A4A4] text-[14px] font-normal leading-6"
                >
                  Budget <span className="text-red-400">*</span>
                </label>
                <select
                  className={`border-b rounded w-full text-black text-base placeholder:[#A4A4A4] placeholder:text-[14px] outline-none my-2 ${
                    ContactFormik.touched.budget && ContactFormik.errors?.budget
                      ? "border-red-500"
                      : "border-[#929292]"
                  }`}
                  name="whatever"
                  id="frm-whatever"
                  onChange={(event) => {
                    ContactFormik.setFieldValue("budget", event?.target?.value);
                  }}
                  value={ContactFormik.values?.budget}
                >
                  <option value="">Select </option>
                  <option value="10K-50K">10K-50K</option>
                  <option value="50K-1L">50K-1L</option>
                  <option value="1L-2L">1L-2L</option>
                  <option value="2L-5L">2L-5L</option>
                  <option value="5L+">5L+</option>
                </select>
              </div>
              {ContactFormik.touched.budget && ContactFormik.errors?.budget ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito -mt-1">
                  {ContactFormik.errors?.budget}
                </p>
              ) : null}
              <div className="flex flex-col mt-5 midxmd:col-span-2">
                <label
                  htmlFor="idea"
                  className="text-[16px] mb-2  font-poppins500 text-black"
                >
                  {quote
                    ? " Tell Us About Your Idea"
                    : "Add additional required skills"}
                  <span className="text-red-400  px-1 font-bold">*</span>
                </label>
                <textarea
                  rows={3}
                  maxLength={500}
                  id="description"
                  name="description"
                  onChange={(val) => {
                    ContactFormik.setFieldValue(
                      "description",
                      val.target.value
                    );
                  }}
                  value={ContactFormik.values?.description}
                  className={`w-[100%] border  
                    border-gray-300
                    ${
                      ContactFormik.touched.description &&
                      ContactFormik.errors?.description
                        ? "border-red-500"
                        : "border-[#929292]"
                    }
                   px-2 mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary text-lg relative z-20 resize-none placeholder-gray-300 placeholder:text-[16px] font-poppins500 t`}
                  placeholder={quote ? "Share your thoughts" : "Add  skills"}
                />
              </div>
              {ContactFormik.touched.description &&
              ContactFormik.errors?.description ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito -mt-1">
                  {ContactFormik.errors?.description}
                </p>
              ) : null}
              <div className="flex justify-end gap-4">
                <button
                  disabled={isLoading}
                  class="bg-transparent text-black text-base py-2 px-6 rounded w-[120px] mt-2  font-semibold border border-black"
                  onClick={() => props?.onPress()}
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading}
                  class="bg-[#0CBAE1] text-white text-base py-2 px-4 rounded w-[120px] mt-2  font-semibold flex items-center justify-center"
                  onSubmit={ContactFormik.handleSubmit}
                >
                  {isLoading && (
                    <Oval
                      visible={true}
                      height="20"
                      width="20"
                      color="#FFF"
                      secondaryColor="#FFF"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      strokeWidth={5}
                      wrapperClass="mr-2"
                    />
                  )}{" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteModal;
