import React from "react";
import Home from "./pages/Home";
import PortfolioPage from "./pages/PortfolioPage";
import OcrIcr from "./pages/OcrIcr";
import { Route, Routes, useLocation } from "react-router-dom";
import GoToTop from "./components/GoToTop";
import Whatsapp from "./components/Whatsapp";
import Footer from "./components/Footer";
import { useEffect } from "react";
import AboutUs from "./pages/AboutUs";
import ReactGA from "react-ga4";
import DetailedBlog from "./pages/DetailedBlog";
import AllBlogPage from "./pages/AllBlogsPage";
import ServicesPage from "./pages/ServicesPage";
import HireDeveloperDetails from "./pages/HireDeveloperDetails";
import HireUsMobile from "./pages/hireUsMobile";

const TRACKING_ID = "G-65J0K14JMS"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <div className="w-full mx-auto relative overflow-hidden scroll-smooth bg-white">
        <div className={`mx-auto`}>
          <>
            <ScrollToTop />
            <Routes>
              <Route path="/portfolio/:itemId" element={<PortfolioPage />} />
              <Route path="/orc-icr" element={<OcrIcr />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/all-blogs" element={<AllBlogPage />} />
              <Route path="/blog-details/:blogsId" element={<DetailedBlog />} />
              <Route path="/hire-developer-mobile" element={<HireUsMobile />} />

              <Route
                path="/services-page/:serviceId"
                element={<ServicesPage />}
              />
              <Route
                path="/hire-developer/:developerId"
                element={<HireDeveloperDetails />}
              />
              <Route name="HOME" path="/" element={<Home />} />
            </Routes>
          </>
          <GoToTop />
          <Whatsapp />
          <Footer />
        </div>
      </div>
    </>
  );
}
export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return null;
}
