import React from "react";
import { servicesList } from "../constants/services";
import { useNavigate } from "react-router-dom";

const ServicesDropdown = () => {
  const navigate = useNavigate();

  const onNavigate = (e, v) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (v?.title === "OCR-ICR") {
      navigate("/orc-icr");
    } else {
      navigate(`/services-page/${v?.title}`);
    }
  };

  return (
    <>
      <div className="service-menu shadow-xl mt-[22px] bg-white justify-center z-[99999]">
        <div className="h-auto justify-center align-center py-2 px-4 text-left">
          <div className="flex flex-col justify-center">
            {servicesList?.map((v) => (
              <div
                className="flex text-[16px] font-extralight hover:text-[#00AAC5] text-left py-1 cursor-pointer"
                onClick={(e) => onNavigate(e, v)}
              >
                {v?.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDropdown;
