import Group from "../assets/hireDev/group.png";
import setting from "../assets/hireDev/stakeholder.png";
import Calendar from "../assets/hireDev/calendar.png";
import glob from "../assets/hireDev/globe.png";
import programming from "../assets/hireDev/programming.png";

export const hireDeveloper = [
  {
    id: 1,
    developerProfile: "Hire iOS Developers",
    title: "Elevate Your iOS Development with Top-Tier Talent",
    subTitle: "Crafting iOS Excellence: Elevate Your Digital Experience",
    description:
      "Unlock the potential of your iPad and iPhone applications by hiring our elite developers. Our remote development team specializes in creating sophisticated, high-performance mobile applications for iPad and iPhone. Utilizing advanced AI/ML algorithms and integrating cutting-edge IoT technologies, we enhance user interactivity and deliver seamless, intuitive experiences.",
    services: [
      {
        id: 1,
        name: "Custom iOS App Development",
        icon: Group,
        description:
          "Our engineers help build remote iOS apps, using technologies such as Blockchain, AI/ML, IoT, etc. to produce robust apps.",
      },
      {
        id: 2,
        name: "iOS App Re-Engineering",
        icon: glob,
        description:
          "In re-engineering iOS applications, our full-stack iOS application developers are swift to provide boost functionality accordingly.",
      },
      {
        id: 3,
        name: "Migration & upgradation for iOS",
        icon: Calendar,
        description:
          "Our iPhone app engineers make sure to keep the iOS apps updated with the latest trends for a smoother experience.",
      },
      {
        id: 4,
        name: "Maintenance & Control for iOS",
        icon: setting,
        description:
          "In order to help you improve the success rate of your app, our iOS developers are well equipped with the best technologies.",
      },
      {
        id: 5,
        name: "Dedicated iOS Developers",
        icon: programming,
        description:
          "Our devoted iOS developers use the latest mobile technologies to create immersive and creative applications with total functionality.",
      },
    ],
  },
  {
    id: 2,
    developerProfile: "Hire Android Developers",
    title: "Elevate Your Mobile Experience with Our Android Developers",
    subTitle: "Android Excellence: Crafting Seamless Mobile Solutions",
    description:
      "Transform your app vision into reality with our skilled Android developers. From sleek UI design to robust backend development, we ensure your app stands out in the crowded market. Partner with us for innovation-driven mobile experiences. Let's build your next success story together!",
    services: [
      {
        id: 1,
        name: "Blockchain Android Apps",
        icon: Group,
        description:
          "Our dedicated programmers of Android apps are skilled to exploit Blockchain technology to create apps according to your requirements and needs.",
      },
      {
        id: 2,
        name: "Committed Android Developers",
        icon: glob,
        description:
          "Hire our dedicated Android app developers to expand the strength of growth of your app and create feature stacked and stable Android applications.",
      },
      {
        id: 3,
        name: "AI/ML Learning Apps",
        icon: Calendar,
        description:
          "Our Android app developers can create smart and imaginative AI-based mobile applications backed with ability implement complex ML algorithms.",
      },
      {
        id: 4,
        name: "Android App Integration",
        icon: setting,
        description:
          "In order to maximise operational performance, get your Android apps blended seamlessly with your backend applications (CMS, ERP, CRM, ECM etc.).",
      },
      {
        id: 5,
        name: "Android App Consultation",
        icon: programming,
        description:
          "Our devoted iOS developers use the latest mobile technologies to create immersive and creative applications with total functionality.A team of backend specialists, consultants, specialists, developers, testers, coders and geeks. Scalability, robustness, and flexibility - we know our jobs right!",
      },
    ],
  },
  {
    id: 3,
    developerProfile: "Hire Flutter Developers",
    title: "Discover Top Flutter Developers",
    subTitle: "Fluttering Magic: CraftingCross-Platform Excellence",
    description:
      "Empower your projects with our elite Flutter developers. With finesse and expertise, we craft sleek, cross-platform apps that captivate and engage users. Infused with AI/ML and a touch of IoT, our solutions bring interactivity to life with unparalleled professionalism. Choose excellence, choose Fluttering Magic",
    services: [
      {
        id: 1,
        name: "Flutter Consultation",
        icon: Group,
        description:
          "Hire Flutter app developers and consultants who have worked on fast and robust applications for all businesses.",
      },
      {
        id: 2,
        name: "Cross Platform Apps",
        icon: glob,
        description:
          "We create cross-platform applications that work effortlessly across platforms with the aid of committed Flutter app programmers.",
      },
      {
        id: 3,
        name: "Flutter for enterprises",
        icon: Calendar,
        description:
          "Our team of Fultter developers create mobile apps for large-scale businesses that are scalable, efficient, and stable.",
      },
      {
        id: 4,
        name: "Native Apps Development",
        icon: setting,
        description:
          "Our Flutter application programmers create native-quality (Android & iOS) apps that are fast, up to date and stable.",
      },
      {
        id: 5,
        name: "Dedicated Flutter Developers",
        icon: programming,
        description:
          "We have a experienced team of Flutter app developers for developing robust mobile apps for leading tech companies globally.",
      },
    ],
  },
  {
    id: 4,
    developerProfile: "Hire Xamarin Developers",
    title: "Hire our Top-notch  Elite Xamarin Developers",
    subTitle: "Xamarin Alchemy: Where Cross-Platform Magic Happens",
    description:
      "Transform your mobile experience with our top Xamarin developers. Blending the power of AI/ML and the innovation of IoT, we craft cross-platform wonders that captivate and engage users. Our expertise turns every app into an enchanting masterpiece of interactivity and performance.",
    services: [
      {
        id: 1,
        name: "Custom Xamarin Development",
        icon: Group,
        description:
          "Our Xamarin App Developers have 15+ years experience of managing mobile applications and offering expert solutions.",
      },
      {
        id: 2,
        name: "Xamarin Forms",
        icon: glob,
        description:
          "Hire Xamarin app developers online for custom app development needs. Our offshore Xamarin app programmers use Xamarin Forms.",
      },
      {
        id: 3,
        name: "Maintenance & Migration",
        icon: Calendar,
        description:
          "Our offshore Xamarin app developers have rich experience in building mobile apps and providing expert migration services.",
      },
      {
        id: 4,
        name: "Rich as Native",
        icon: setting,
        description:
          "Our Xamarin application developers build feature-rich, advanced and secure native quality (Android & iOS) application.",
      },
      {
        id: 5,
        name: "Dedicated Xamarin Developers",
        icon: programming,
        description:
          "As a leading software company in the world, we provide trusted and certified Xamarin app developers for building robust mobile applications.",
      },
    ],
  },
  {
    id: 5,
    developerProfile: "Hire Selenium Testers",
    title: "Unleash Quality Assurance Magic with Our Selenium Testers",
    subTitle: "Selenium Sorcery: Ensuring Seamless Software Performance",
    description:
      "Empower your projects with our skilled Selenium testers. With meticulous attention to detail and a touch of sorcery, we ensure your software meets the highest standards of quality and reliability. Trust us to weave the magic of flawless performance into your digital creations.",
    services: [
      {
        id: 1,
        name: "Automated Functional Testing",
        icon: Group,
        description:
          "Our professional Selenium testers can help you verify integrations for a variety of scenarios using functional tests for web, computer, and mobile apps.",
      },
      {
        id: 2,
        name: "Integration & Development",
        icon: glob,
        description:
          "A good framework helps re-usage, higher code readability, and script maintenance cost—using automation tools our expert Selenium testers can do it all.",
      },
      {
        id: 3,
        name: "App Testing Methodology",
        icon: Calendar,
        description:
          "Process automation, infrastructure review, and implementation are all supported. Our Selenium testers provide you with appropriate solutions.",
      },
      {
        id: 4,
        name: "Continuous Integrations",
        icon: setting,
        description:
          "Our Selenium developers who are familiar with JANKINS, Maven, Test NG, and other software, ensure that the project is handled smoothly.",
      },
      {
        id: 5,
        name: "Dedicated Selenium Tester",
        icon: programming,
        description:
          "With the use of Selenium, our testers significantly raise the code reuse, portability, and readability while lowering script maintenance costs.",
      },
    ],
  },
  {
    id: 6,
    developerProfile: "Hire MEAN Stack Developers",
    title: " Unlock Your project  Potential with Our MEAN Stack Developer",
    subTitle: "Mappoptimist: Where MEAN Stack Mastery Meets Innovation",
    description:
      "Partner with our top-tier MEAN stack developer to craft robust, scalable web applications. Harnessing MongoDB, Express.js, AngularJS (or Angular), and Node.js, we deliver excellence in every line of code. Choose innovation, choose Mappoptimist.",
    services: [
      {
        id: 1,
        name: "Dedicated Development",
        icon: Group,
        description:
          "Employ Algoworks's dedicated MEAN Stack developers and create powerful & feature stacked web apps for your specific business requirements.",
      },
      {
        id: 2,
        name: "Custom CMS Development",
        icon: glob,
        description:
          "Our MEAN Stack developers are specialised in creating fantastic, user-friendly and responsive CMS for small to large-scale companies.",
      },
      {
        id: 3,
        name: "Solutions for Business Growth",
        icon: Calendar,
        description:
          "Hire MEAN Stack developers who are experienced with development of enterprise application that are cost-effective and flexible across platforms.",
      },
      {
        id: 4,
        name: "MEAN Stack Maintenance",
        icon: setting,
        description:
          "Our Mean Stack maintenance team has expertise in the maintenance and management of MEAN Stack web applications for several MNCs.",
      },
      {
        id: 5,
        name: "MEAN Stack Integration & Migration",
        icon: programming,
        description:
          "In integrating & migrating enterprise web app to MEAN Stack-based systems, our developers have hands-on knowledge and expertise.",
      },
    ],
  },
  {
    id: 7,
    developerProfile: "Hire MERN Stack Developers",
    title: "Hire Top MERN Stack Developers",
    subTitle: "MERN Marvels: Crafting Seamless Web Wonders",
    description:
      "Transform your digital realm with our expert MERN Stack developers. Leveraging the power of MongoDB, Express, React, and Node.js, we create innovative, high-performance web applications. Click to infuse your projects with unparalleled innovation and expertise!",
    services: [
      {
        id: 1,
        name: "MERN Stack Expertise",
        icon: Group,
        description:
          "Build complex Web Application using MERN STACK for businesses that are deployed either on the web, while maintaining strict security protocols.",
      },
      {
        id: 2,
        name: "Custom CMS Development",
        icon: glob,
        description:
          "Our MERN Stack developers are specialised in creating top performing user-friendly and responsive CMS for small to large-scale companies.",
      },
      {
        id: 3,
        name: "Solutions for Business Growth",
        icon: Calendar,
        description:
          "Hire MERN Stack developers who are skilled in the development of enterprise applications that are cost-effective and flexible across platforms.",
      },
      {
        id: 4,
        name: "MERN Stack Maintainance",
        icon: setting,
        description:
          "Our MERN Stack maintenance team has expertise in the maintenance and management of MERN Stack web applications for several corporate firms & MNCs.",
      },
      {
        id: 5,
        name: "MERN Stack Integration & Migration",
        icon: programming,
        description:
          "Acubeapps has hands-on experience in migrating and porting platforms and business application over to the MERN stack framework.",
      },
    ],
  },
  {
    id: 8,
    developerProfile: "Hire Java Developers",
    title: "Elevate Your Team with Java's Premier Developers",
    subTitle: "Java Journeys: Crafting Robust Web Solutions",
    description:
      "Join forces with our coding virtuosos to sculpt robust web solutions powered by Java. Experience seamless excellence as we infuse innovation into your digital realm. Choose professionalism, choose simplicity, choose Java Journeys.",
    services: [
      {
        id: 1,
        name: "Experienced Java Developers",
        icon: Group,
        description:
          "Get SaaS applications or any custom Java web apps worked for your business with the assistance of our experienced Java engineers.",
      },
      {
        id: 2,
        name: "Custom Java Development",
        icon: glob,
        description:
          "Recruit Java developers who've solid involvement and experience with secure, lively and safe custom web apps.",
      },
      {
        id: 3,
        name: "Upgradation & Migration",
        icon: Calendar,
        description:
          "Our Java Developers will make sure that the custom web app is constantly updated with the best user design and experience.",
      },
      {
        id: 4,
        name: "Create Enterprise Java Apps",
        icon: setting,
        description:
          "Recruit committed Java developers for adaptable, powerful, and secure undertaking enterprise stage utility development.",
      },
      {
        id: 5,
        name: "Java Integration",
        icon: programming,
        description:
          "Flawlessly coordinate your current Java application with other custom applications with the assistance of our experienced engineers.",
      },
    ],
  },
  {
    id: 9,
    developerProfile: "Hire PHP Developers",
    title: "Unleash PHP Excellence with Our Premier Developers",
    subTitle: "PHP Prowess: Sculpting Dynamic Digital Realms",
    description:
      "Empower your digital presence with our elite PHP developers. From crafting dynamic web magic to infusing AI/ML and IoT innovations, we redefine the possibilities of online experiences. Elevate your digital journey today with PHP Prowess.",
    services: [
      {
        id: 1,
        name: "Custom PHP Web Development",
        icon: Group,
        description:
          "Hire PHP developers who have strong experience in building robust and high-performance PHP web applications just like us.",
      },
      {
        id: 2,
        name: "PHP Upgradation & Migrations",
        icon: glob,
        description:
          "With our experience we integrate and upgrade your existing app with PHP be it on Python, .NET, Nodejs or others.",
      },
      {
        id: 3,
        name: "Ongoing Maintenance & Management",
        icon: Calendar,
        description:
          "Our offshore PHP developers keep your web applications managed & maintained following the best-practices.",
      },
      {
        id: 4,
        name: "Full Stack PHP Web Development",
        icon: setting,
        description:
          "Employ our PHP full-stack developers who can work on your web apps' font-end and backend as per your requirement.",
      },
      {
        id: 5,
        name: "Committed PHP Developers",
        icon: programming,
        description:
          "We at Acubeapps, have dedicated PHP web developers who can work in diverse industries and develop scalable web solutions as needed.",
      },
    ],
  },
  {
    id: 10,
    developerProfile: "Hire React JS Developers",
    title: "Find Exceptional ReactJS Developers",
    subTitle: "ReactJS Radiance: Unleashing Seamless Interactivity",
    description:
      "Transform your web projects with our top ReactJS talent. Infusing AI/ML and IoT magic, we craft web experiences that are not just seamless but transformative. Your path to digital excellence starts here!",
    services: [
      {
        id: 1,
        name: "Dedicated React.js Developers",
        icon: Group,
        description:
          "Our dedicated React.js developers use React library to create immersive user interfaces for web applications.",
      },
      {
        id: 2,
        name: "Migration & Integration",
        icon: glob,
        description:
          "Our team of React.js developers can help you integrate your current web applications with ReactJS.",
      },
      {
        id: 3,
        name: "Custom Web Apps",
        icon: Calendar,
        description:
          "Our React.js developers create phenomenal custom web apps and make sure to deliver projects on time.",
      },
      {
        id: 4,
        name: "React Apps for Business",
        icon: setting,
        description:
          "Our top-notch team of React.js programmers can create web apps that are scalable, stable, and safe for enterprises.",
      },
      {
        id: 5,
        name: "UI UX Designing",
        icon: programming,
        description:
          "We aspire to provide your customers with great UX across all channels by using UI/UX libraries to every extent possible.",
      },
    ],
  },
  {
    id: 11,
    developerProfile: "Hire Node JS Developers",
    title: "Elevate Your Team with Our Premier Node.js Developer",
    subTitle: "Node.js Nexus: Crafting Dynamic Web Solutions",
    description:
      "Empower your projects with our top Node.js developer. Experience dynamic web solutions tailored to your needs. Choose excellence, choose Node.js Nexus.",
    services: [
      {
        id: 1,
        name: "Native Node.JS Apps",
        icon: Group,
        description:
          "Our Node.js developers can build cross-platform, modular, and high-performance mobile applications for all enterprises.",
      },
      {
        id: 2,
        name: "Node.JS web apps",
        icon: glob,
        description:
          "Our Node.js developers work with small and large scale business, and create web applications that are scalable, stable, and safe.",
      },
      {
        id: 3,
        name: "Migration & Integration",
        icon: Calendar,
        description:
          "Our dedicated Node.js developers help with the migration and integration of your current web apps to Node.js smoothly and seamlessly.",
      },
      {
        id: 4,
        name: "Node.JS Consultation",
        icon: setting,
        description:
          "Our team of Node.js developers offer expert consultation on Node.js development, customization, and deployment.",
      },
      {
        id: 5,
        name: "Dedicated Node.JS Developers",
        icon: programming,
        description:
          "Our experts Node.js programmers use Node.js library to build immersive user interfaces for business applications.",
      },
    ],
  },
  {
    id: 12,
    developerProfile: "Hire Vue JS Developers",
    title: "Explore Vue.js Excellence with Our Top Developers",
    subTitle: "Vue.js Visionaries: Architecting Cross-Platform Solutions",
    description:
      "Embark on your digital journey with our skilled Vue.js developers. Leveraging advanced AI/ML and IoT integration, we architect cross-platform solutions that redefine technical excellence.",

    services: [
      {
        id: 1,
        name: "10+ Years of Experience",
        icon: Group,
        description:
          "Our Vue.js developers and engineers are skilled and knowledgeable about the exact merits and demerits of development.",
      },
      {
        id: 2,
        name: "Integrity & Transparency",
        icon: glob,
        description:
          "We value your privacy, therefore your idea is safe with us. We work in a transparent manner and adhere to rigorous NDA",
      },
      {
        id: 3,
        name: "Free No Obligation Quote",
        icon: Calendar,
        description:
          "We will give you with a no-cost quotation within 24 hours of receiving your project concept without any more questions.",
      },
      {
        id: 4,
        name: "Hassle-free Project Management",
        icon: setting,
        description:
          "While our professional project managers works on your project, we keep you free of complex project management issues.",
      },
      {
        id: 5,
        name: "Transparency is Guaranteed",
        icon: programming,
        description:
          "We communicate with you on a frequent basis via Skype, email, and phone to keep you up to date on the status of your project.",
      },
    ],
  },
  {
    id: 13,
    developerProfile: "Hire Python Developers",
    title: "Explore with Our Premier Python Developers",
    subTitle: "Python Enchantment: Unleashing Cross-Platform Marvels",
    description:
      "Join us in exploring the world of Python development at its finest.Experience the power of Python with our skilled developers. With AI/ML and a touch of IoT, we create seamless cross-platform wonders that make your projects come alive. Join us for a journey into effortless interactivity.",
    services: [
      {
        id: 1,
        name: "Python Maintenance Services",
        icon: Group,
        description:
          "The Python developer team has acquired tremendous expertise in running Python software and are available for support.",
      },
      {
        id: 2,
        name: "Endeavour Python Applications",
        icon: glob,
        description:
          "Our dependable Python engineers fabricate applications for goliath ventures which are additionally adaptable and dependable.",
      },
      {
        id: 3,
        name: "Custom Python Web Apps",
        icon: Calendar,
        description:
          "Recruit Python designers who've solid involvement with noticeably secure, lively and excessive acting Python web application improvement.",
      },
      {
        id: 4,
        name: "Committed Python Developers",
        icon: setting,
        description:
          "Recruit committed Python web designers proficient in constructing secure, flexible and effective internet programs for clients globally.",
      },
      {
        id: 5,
        name: "Python Migration And Integration",
        icon: programming,
        description:
          "Move your modern-day programs to Python in a risk free manner with our reliable and dependable Python coders that make it secure.",
      },
    ],
  },
  {
    id: 14,
    developerProfile: "Hire Angular Developers",
    title: "Elevate Your Team with Our Top-Notch Angular Developer",
    subTitle: "Angular Architects: Building Dynamic Web Experiences",
    description:
      "Join forces with our elite Angular developer to craft dynamic and interactive web applications. With expertise in front-end development, component-based architecture, and seamless data binding, we deliver exceptional user experiences that set your projects apart.Elevate your team with Angular Architects. Hire us to unlock the potential of your web projects!",
    services: [
      {
        id: 1,
        name: "Dedicated Angular Developers",
        icon: Group,
        description:
          "Hire dedicated engineers for AngularJs in developing powerful web applications for a variety of global clientele.",
      },
      {
        id: 2,
        name: "Engineering Service Network",
        icon: glob,
        description:
          "With an improved feel, our remote Angular js developers can help you redesign and migrate your existing applications to Angular.",
      },
      {
        id: 3,
        name: "Enterprise Angular Web Apps",
        icon: Calendar,
        description:
          "Your remote AngularJS developers working overseas make sure that the web applications are indestructible, stable and safe at the same time.",
      },
      {
        id: 4,
        name: "AngularJS Custom Development",
        icon: setting,
        description:
          "Hire Angular programmers who use the best web app development practices to create custom programs according to your needs.",
      },
      {
        id: 5,
        name: "Web Interface Development",
        icon: programming,
        description:
          "Our Angular developers are ready enough to offer the best in class web development with tech skills like XHR, XHTML, CSS, and JS.",
      },
    ],
  },
  {
    id: 15,
    developerProfile: "Hire SalesForce Developers",
    title: "Elevate Your Team with Top Salesforce Developers",
    subTitle: "Salesforce Fusion: Uniting AI, IoT, and Seamless Apps",
    description:
      "Empower user experiences with the dynamic fusion of Salesforce's power and our innovation. Craft cross-platform magic effortlessly. Partner with our seasoned Salesforce developers for unparalleled professionalism and transformative solutions.",
    services: [
      {
        id: 1,
        name: "Salesforce Consulting",
        icon: Group,
        description:
          "Acubeapps is humbled with a family of the nation's top Certified Salesforce Developers, Consultants and Architects to maximize your ROI.",
      },
      {
        id: 2,
        name: "Custom Development",
        icon: glob,
        description:
          "Our experienced Salesforce developer solutions will deliver better productivity and improve your businesses efficiency.",
      },
      {
        id: 3,
        name: "Salesforce Cloud Setup",
        icon: Calendar,
        description:
          "The Salesforce Cloud Solutions provided by our developers on more than 16 Clouds help customers gauge insights into the business, selectively and sophisticatedly.",
      },
      {
        id: 4,
        name: "AngularJS Custom Development",
        icon: setting,
        description:
          "Hire Angular programmers who use the best web app development practices to create custom programs according to your needs.",
      },
      {
        id: 5,
        name: "Support Services",
        icon: programming,
        description:
          "Our Salesforce experts after launching more than 25 apps on Appexchange have acquired expertise in running Salesforce and are available for effective support & maintenance.",
      },
    ],
  },
  {
    id: 16,
    developerProfile: "Hire Hadoop Developers",
    title: "Power Your Big Data Projects with Top Hadoop Developers",
    subTitle: "Hadoop Wizardry: Unleash Big Data Potential",
    description:
      "Join forces with our elite Hadoop developers for seamless big data solutions. From processing to management, we deliver expertise that drives success. Join us  for innovation-driven digital journeys!",
    services: [
      {
        id: 1,
        name: "Hadoop Development",
        icon: Group,
        description:
          "Our developers with Hadoop acknowledge the standard challenge and provide the opportunity to offer innovative Hadoop solutions.",
      },
      {
        id: 2,
        name: "Implementing Hadoop",
        icon: glob,
        description:
          "To execute the concept, our developers provide a broad range of centralized batch processing infrastructures.",
      },
      {
        id: 3,
        name: "Integration of Hadoop",
        icon: Calendar,
        description:
          "Use the incorporation of Hadoop to help record-based Hadoop data flow using the Hadoop processing scale and solving your business problems.",
      },
      {
        id: 4,
        name: "Configuration and Optimization",
        icon: setting,
        description:
          "Our Hadoop developers take as a challenge to optimize the Hadoop parameter using the application.",
      },
      {
        id: 5,
        name: "Aggregation and Data Mining",
        icon: programming,
        description:
          "To enhance the consistency and integration of results, our Hadoop experts optimise reliability and productivity.",
      },
    ],
  },
  {
    id: 17,
    developerProfile: "Hire UI-UX Developers",
    title: "Join Forces with Our UX-UI Maestro!",
    subTitle:
      "Engineering Intuitive Interfaces: Where Precision Drives Engagement ",
    description:
      "Craft mesmerizing digital realms with our top-notch UI/UX developer! Our maestro brings brilliance to every project, ensuring captivating user experiences that merge seamlessly with business objectives through collaborative brilliance. Stay ahead with our relentless pursuit of design trends and technologies. Elevate your digital presence with our UI/UX wizardry—click now to embark on an unforgettable journey!",
    services: [
      {
        id: 1,
        name: "Wide Range of Experts",
        icon: Group,
        description:
          "Acubeapps consists of a wide range of UX/UI designers who provide exceptional design services making the whole design process easier.",
      },
      {
        id: 2,
        name: "Hypergrowth Approach",
        icon: glob,
        description:
          "We hone a thorough understanding of the industry and deliver designs that stand out and make sure to come up with the best ideas",
      },
      {
        id: 3,
        name: "Holistic Business Support",
        icon: Calendar,
        description:
          "We put together multidisciplinary teams of software industry experts for you, including project leaders and the best strategy advisors.",
      },
      {
        id: 4,
        name: "Truly Agile Process",
        icon: setting,
        description:
          "To ensure the highest quality and accountability of our experts' work, we pick the best practises of agile methodology and apply them to other project areas.",
      },
      {
        id: 5,
        name: "Code Reviews",
        icon: programming,
        description:
          "The code is checked in in smaller bits to make it easier to handle the production. Peer-to-peer or online code review becomes even more effective.",
      },
    ],
  },
  {
    id: 18,
    developerProfile: "Hire AI-ML Experts",
    title: "Unleash the Power of AI/ML with Our Top-Notch Developer",
    subTitle: "Mappoptimist: Your Partner in AI/ML Excellence",
    description:
      "Experience the pinnacle of AI/ML innovation with our expert developer. From data-driven insights to automated decision-making, we bring unparalleled expertise to every project. Elevate your business with Mappoptimist—where AI/ML mastery meets transformative solutions.",
    services: [
      {
        id: 1,
        name: "Recommendation Systems",
        icon: Group,
        description:
          "With the help of our committed Machine Learning experts, create AI solutions like recommendation systems that allow users to buy items they're interested in based on their previous purchase habits.",
      },
      {
        id: 2,
        name: "Chatbots with AI",
        icon: glob,
        description:
          "Incorporating a professional chatbot on your website will help you save money on service quality. Employ our Machine Learning experts online to build chatbots that are both humane and relatable.",
      },
      {
        id: 3,
        name: "AI Process Automation",
        icon: Calendar,
        description:
          "Our Machine Learning developers create AI solutions and software which can help you improve your inventory management, sales pipeline, and financial operations.",
      },
      {
        id: 4,
        name: "Advanced Business Analytics",
        icon: setting,
        description:
          "Our Machine Learning developers can get the details you need and analyse data using state-of-the-art tools to help you make the right choices and reduce risk.",
      },
      {
        id: 5,
        name: "Artificial Neural Networks",
        icon: programming,
        description:
          "Our experienced Machine Learning developers will assist you in the development of neural network-based AI systems that can automate the classification tasks.",
      },
    ],
  },
];
