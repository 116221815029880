import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { servicesList } from "../constants/services";
import { useNavigate, useParams } from "react-router-dom";
import QuoteModal from "./QuoteModal";

const Services = ({
  title = "Our Services",
  classes = "",
  restrictServiceId = [],
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const { serviceId } = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  });

  const onCloseModal = async () => {
    setShowModal(false);
  };
  return (
    <>
      {showModal ? (
        <QuoteModal
          onPress={(v) => {
            onCloseModal(v);
          }}
        />
      ) : null}
      <motion.div
        className="w-screen  relative z-0 py-10 scroll-mt-24 bg-white "
        id="Services"
        name="Services"
        ref={ref}
        variants={{
          hidden: { opacity: 1, y: 0 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="visible"
        animate={mainControls}
        transition={{ duration: 1.6 }}
      >
        <div className="flex flex-col justify-start items-center pb-10 ">
          <h1 className="text-[30px] py-2 lmd:text-[48px] font-poppins bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
            {title}
          </h1>
        </div>
        <div className="flex flex-wrap col-span-3 justify-center items-center w-full md:px-20 px-8 gap-20 pb-10">
          {servicesList?.map((i, index) => {
            if (![serviceId, ...restrictServiceId]?.includes(i?.title)) {
              return (
                <div className="bg-white  md:w-[340px] h-auto  rounded-md border border-blue-500 p-4 hover:border-none shadow-xl  transform transition duration-500 hover:scale-110  ">
                  <div
                    className={`flex flex-col justify-center items-center md:space-y-8  my-10 ${classes}`}
                  >
                    <img src={i.icon} alt="SEO" className="w-28 h-28" />
                    <h1 className="text-[24px] font-poppins ">{i.title}</h1>
                    <h1 className="text-[16px] leading-snug font-light text-center">
                      {i.shortDescription}
                    </h1>
                    <div className="flex justify-around w-full md:px-2 px-2 md:flex-row flex-col md:gap-0 gap-4 md:pt-0 pt-4">
                      <button
                        className=" rounded-lg px-4 h-10 text-[16px] font-semibold border border-black"
                        onClick={() =>
                          i.title === "OCR-ICR"
                            ? navigate("/orc-icr")
                            : navigate(`/services-page/${i.title}`)
                        }
                      >
                        Read More
                      </button>
                      <button
                        className=" rounded-lg px-4 h-10 text-[16px] font-semibold border border-[#53C6EC] bg-[#53C6EC] text-black"
                        onClick={() => setShowModal(true)}
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </motion.div>
    </>
  );
};

export default Services;
